import { SubmitButtons } from '@components/shared/Buttons';
import { PolicyRuleType } from '@typeDef/Policy';

const RulesConfirmation = ({
  rulesList,
  isLoading,
  onSubmit,
  setShowConfirmation
}: {
  rulesList: PolicyRuleType[];
  isLoading: boolean;
  onSubmit: () => void;
  setShowConfirmation: (val: boolean) => void;
}) => {
  return (
    <>
      <div className="border-b border-neutral-300 mb-3 font-semibold text-xl pb-3">
        Policy Rule changes
      </div>
      <div className="mb-3">
        <p className="textMuted mb-3">
          Are you sure you want to publish these changes?
        </p>
        <p className="mb-3">Summary:</p>
        <div className="max-h-70 overflow-auto">
          {rulesList
            .filter((x: PolicyRuleType) => !!x.status)
            .map((x: PolicyRuleType) => (
              <li
                className="text-sm"
                key={`${x.label}--${x.status}--${x.lower_threshold}`}
              >
                {x.label}: {x.status}
              </li>
            ))}
        </div>
      </div>

      <div className="flex gap-2 justify-end">
        <SubmitButtons
          onClose={() => setShowConfirmation(false)}
          onSubmit={onSubmit}
          submitLabel="Confirm"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export { RulesConfirmation };
