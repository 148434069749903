import { useState, ReactNode, useEffect } from 'react';
import { Button } from '@components/shared/Buttons';
import { useUser } from '@context/UserProvider';
import { MdHistory } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { marked } from 'marked';
import { Policy } from '@api/types';

type PolicyViewType = {
  policy: Policy;
  showHistory?: boolean;
  setShowHistory?: (val: boolean) => void;
  showAdd?: boolean;
  setShowAdd?: (val: boolean) => void;
  showRules?: boolean;
  children?: ReactNode;
};

const PolicyView = ({
  policy,
  showHistory,
  setShowHistory,
  showAdd,
  setShowAdd,
  showRules = false,
  children
}: PolicyViewType) => {
  const [tabActive, setTabActive] = useState<string>('description');
  const { user } = useUser();

  useEffect(() => {
    if (tabActive !== 'description') {
      setTabActive('description');
    }
  }, [policy?.code]);

  return (
    <div className="py-6">
      <div className="flex justify-between">
        <div className="flex gap-4 text-lg items-baseline">
          {(policy?.guidelines || showRules) && (
            <Button
              onClick={() => setTabActive('description')}
              style={`hover:text-cta ${tabActive === 'description' && 'text-cta border-b-2 font-semibold border-cta pb-2 mb-8'}`}
              title="Description"
            />
          )}
          {policy?.guidelines && (
            <Button
              style={`hover:text-cta ${tabActive === 'guidelines' && 'text-cta border-b-2 font-semibold border-cta pb-2 mb-8'}`}
              onClick={() => setTabActive('guidelines')}
              title="Internal guidelines"
            />
          )}
          {showRules && (
            <Button
              style={`hover:text-cta ${tabActive === 'rules' && 'text-cta border-b-2 font-semibold border-cta pb-2'}`}
              onClick={() => setTabActive('rules')}
              title="Rules"
            />
          )}
        </div>
        {user?.isUserAdmin && tabActive === 'rules' && (
          <div className="flex justify-between item-start mb-8">
            <div className="flex gap-3">
              <Button
                hiddenTitle="show policy history"
                onClick={() => setShowHistory?.(!showHistory)}
                style="rounded-sm text-sm font-bold flex items-center h-8 px-2 buttonOutlined"
              >
                <MdHistory size="20" className="mr-1" /> History
              </Button>
              <Button
                style="buttonOutlined rounded-sm text-sm font-bold flex items-center h-8 px-2"
                hiddenTitle="add policy Rule"
                onClick={() => setShowAdd?.(!showAdd)}
              >
                <IoMdAdd size="18" />
                New rule
              </Button>
            </div>
          </div>
        )}
      </div>
      {tabActive === 'description' && (
        <div
          id="policy"
          className="prose prose-strong:text-text-white max-w-none text-text-white gap-8"
          dangerouslySetInnerHTML={{
            __html: marked.parse(policy?.description)
          }}
        />
      )}
      {tabActive === 'guidelines' && (
        <div
          id="policy"
          className="prose prose-strong:text-text-white max-w-none text-text-white gap-8"
          dangerouslySetInnerHTML={{
            __html: marked.parse(policy?.guidelines || '')
          }}
        />
      )}
      {tabActive === 'rules' && <div>{children}</div>}
    </div>
  );
};

export { PolicyView };
