import { Interval } from '@typeDef/TimeSeries';
import { DateTime } from 'luxon';
import { useUser } from '@context/UserProvider';

const formatTime = (tickItem: string, interval?: string) => {
  let format;

  switch (interval) {
    case Interval.Day:
      format = 'ccc, dd/LL';
      break;
    case Interval.Week:
      format = 'dd/LL';
      break;
    case Interval.Month:
      format = 'LLLL, y';
      break;
    default:
      format = 'ff';
      break;
  }

  return DateTime.fromISO(tickItem).toFormat(format);
};

const getLocaleTime = (UTCtime: string) => {
  const { platform } = useUser();
  return DateTime.fromISO(UTCtime).setZone(platform?.timezone).toFormat('ff');
};

enum DurationUnit {
  Day,
  Hour,
  Minute,
  Second
}

const formatDuration = (
  durationInSeconds?: number,
  precision: DurationUnit = DurationUnit.Second
) => {
  if (!durationInSeconds && durationInSeconds !== 0) return '';
  const durationParts = [
    {
      unit: DurationUnit.Day,
      value: durationInSeconds / (3600 * 24),
      showAs: 'd'
    },
    {
      unit: DurationUnit.Hour,
      value: (durationInSeconds % (3600 * 24)) / 3600,
      showAs: 'h'
    },
    {
      unit: DurationUnit.Minute,
      value: (durationInSeconds % 3600) / 60,
      showAs: 'm'
    },
    {
      unit: DurationUnit.Second,
      value: durationInSeconds % 60,
      showAs: 's'
    }
  ];
  return durationParts
    .flatMap((part) => {
      if (part.unit <= precision) {
        const value =
          part.unit == precision
            ? Math.round(part.value)
            : Math.floor(part.value);
        if (value > 0) {
          return `${value}${part.showAs}`;
        }
      }
      return [];
    })
    .join(' ');
};

export { formatTime, getLocaleTime, DurationUnit, formatDuration };
