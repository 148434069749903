import { TransparencyReport } from '@api/types';
import { DurationUnit, formatDuration } from '@utils/date';
import { camelCaseToWords, capitalizeFirstLetter } from '@utils/string';
import Papa from 'papaparse';

const getNormalisedData = (
  data: any[],
  headers: { display: string; key: string }[] | null,
  totals: any,
  firstHeader: string = ''
) => {
  if (!headers) {
    headers =
      data && data.length > 0
        ? Object.keys(data[0])
            .sort((a, b) =>
              a === firstHeader ? -1 : b === firstHeader ? 1 : 0
            )
            .map((head) => ({
              display: capitalizeFirstLetter(camelCaseToWords(head)),
              key: head
            }))
        : [];
  }
  const normalizedData = data.map((x: any) =>
    headers?.map(({ display, key }) => ({
      header: display,
      val: x[key] || 0
    }))
  );
  const normalizedTotal = [
    ...(headers.length > 0
      ? [{ header: headers[0].display, val: 'Total' }]
      : []),
    ...headers.slice(1).map(({ display, key }) => ({
      header: display,
      val: totals[key] || 0
    }))
  ];
  return [...normalizedData, normalizedTotal];
};

const getSectionsConfig = (report?: TransparencyReport, platform?: string) => {
  if (!report) return [];
  return [
    {
      title: '1. Orders received from EU Member States',
      sub_title:
        '1.1 Number of Authority Orders to act against illegal content by Member State',
      dataPath: 'illegal_content_orders_per_member_state',
      headers: [
        { display: 'Member state', key: 'member_state' },
        {
          display: 'Number of Authority Orders to act against illegal content',
          key: 'illegal_content_orders'
        }
      ],
      texts: [
        `Total number of EU Member State orders to act against illegal content: ${report.illegal_content_orders}`,
        `Total number of EU Member State orders to provide information about users: ${report.user_information_orders}`,
        `Median Time to inform authority of receipt of an Authority Order: ${formatDuration(
          report.orders_acknowledgement_median_time,
          DurationUnit.Hour
        )}`,
        `Median Time to give effect to the Authority Order: ${formatDuration(
          report.orders_address_median_time,
          DurationUnit.Hour
        )}`
      ]
    },
    {
      sub_title:
        '1.2 Number of Authority Orders to act against illegal content by type of reported illegality',
      dataPath: 'illegal_content_orders_per_policy',
      headers: [
        { display: 'Type of reported illegality', key: 'policy' },
        {
          display: 'Number of Authority Orders to act against illegal content',
          key: 'illegal_content_orders'
        }
      ]
    },
    {
      sub_title:
        '1.3 Number of Authority Orders to provide information by Member States',
      dataPath: 'user_information_orders_per_member_state',
      headers: [
        { display: 'Member state', key: 'member_state' },
        {
          display: 'Number of Authority Orders to provide information',
          key: 'illegal_content_orders'
        }
      ]
    },
    {
      sub_title:
        '1.4 Number of Authority Orders to provide information by reported illegality',
      dataPath: 'user_information_orders_per_policy',
      headers: [
        { display: 'Type of reported illegality', key: 'policy' },
        { display: 'Orders', key: 'user_information_orders' }
      ]
    },
    {
      title: '2. Notices',
      sub_title:
        '2.1 Number of notices submitted and actioned by type of illegal content',
      dataPath: 'notices_per_illegal_policy',
      headers: [
        { display: 'Type of Illegal Content', key: 'policy' },
        { display: 'Notices submitted', key: 'submitted_notices' },
        {
          display: 'Notices with content removal',
          key: 'notices_with_removal'
        }
      ],
      texts: [
        `The median time to action after receiving a notice is: ${formatDuration(
          report.notice_address_median_time,
          DurationUnit.Hour
        )}`
      ]
    },
    {
      title: '3. Own Initiative Content Moderation',
      sub_title: '3.1. EU Content Moderators broken down by EU Language',
      dataPath: 'moderators_per_language',
      headers: [
        { display: 'EU Language', key: 'language' },
        { display: 'Number of Reviewers', key: 'moderators' }
      ]
    },
    {
      sub_title:
        '3.2. Own Initiative Content Actioning in the European Union per Policy',
      dataPath: [
        'enforcements_per_community_guideline_policy',
        'enforcements_per_illegal_policy'
      ],
      firstHeader: 'policy',
      texts: [
        `Overturn rate of appeals on automated content: ${report.overturn_rate_for_automation} %`
      ]
    },
    {
      title: '4. Complaints',
      sub_title: '4.1 Enforcement Appeals and resulting Restored volume',
      dataPath: 'appeals_per_policy',
      headers: [
        { display: 'Policy', key: 'policy' },
        { display: 'Total Appeals on Content Removal', key: 'appeals' },
        { display: 'Content Restores', key: 'overturn' },
        { display: 'Account Restores', key: 'author_overturn' }
      ],
      texts: [
        `The median time needed to review and decide an appeal is: ${formatDuration(
          report.appeal_address_median_time,
          DurationUnit.Hour
        )}`
      ]
    },
    {
      title: '5. Out of Court Disputes',
      texts: [
        `To date, ${platform} has received ${report.out_of_court_disputes} disputes from certified out-of-court settlement bodies.`
      ]
    },
    {
      title: '6. Suspensions for Misuse of the Service',
      texts: [
        `For this reporting period, ${platform} has suspended ${report.terminations} accounts for misuse of service.`
      ]
    },
    {
      title: '7. Service Recipients',
      sub_title: '7.1 Average Monthly Active Users for Each Member State',
      dataPath: 'average_mau_per_member_state',
      headers: [
        { display: 'Member state', key: 'member_state' },
        { display: 'Average Monthly Active Users', key: 'average_mau' }
      ]
    }
  ];
};

const exportToCsv = (report?: TransparencyReport, sectionsConfig?: any) => {
  const sections = sectionsConfig?.map((section: any) => ({
    name: section.sub_title ?? section.title,
    data: section.dataPath && report ? getSectionData(report, section) : []
  }));

  const csvContent = sections
    .map((section: any) => {
      const csvData = Papa.unparse(
        (section.data as any)?.map((row: any) =>
          row.reduce((acc: any, col: any) => {
            acc[col.header] = col.val;
            return acc;
          }, {})
        ),
        { header: true }
      );

      return `${section.name}\n${csvData}\n`;
    })
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'transparency_report.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const getSectionData = (report: TransparencyReport, section: any) => {
  const dataPaths = Array.isArray(section.dataPath)
    ? section.dataPath
    : [section.dataPath];
  const { data, totals } = dataPaths
    .map((p: string) => (report as any)[p])
    .filter((c: any) => !!c)
    .reduce((acc: any, c: any) => {
      return {
        data: [...acc.data, ...c.data],
        totals: Object.fromEntries(
          Array.from(
            new Set([
              ...Object.keys(acc.totals),
              ...Object.keys(c.totals)
            ]).values()
          ).map((k) => [k, (acc.totals[k] || 0) + (c.totals[k] || 0)])
        )
      };
    });
  return getNormalisedData(
    data,
    section.headers,
    totals,
    section.firstHeader || ''
  );
};

export { getSectionsConfig, exportToCsv, getSectionData };
