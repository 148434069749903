import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight
} from 'react-icons/fi';
import { Button } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';

type PaginationProps = {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  setPageSize: (val: number) => void;
  gotoPage: (val: number) => void;
};

const Pagination = ({
  currentPage,
  pageCount,
  pageSize,
  setPageSize,
  gotoPage
}: PaginationProps) => {
  return (
    <div className="flex items-baseline">
      <div className="mr-2.5 flex gap-2">
        <Button
          style="hidden xl:block buttonOutlined rounded-md p-0.5"
          onClick={() => gotoPage(0)}
          disabled={currentPage === 0}
        >
          <FiChevronsLeft size={30} />
        </Button>
        <Button
          style="buttonOutlined rounded-md p-0.5"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <FiChevronLeft size={30} />
        </Button>
        <Button
          onClick={() => null}
          style="hover:bg-transparent active:bg-transparent w-[130px] overflow-hidden"
        >
          Page{' '}
          <strong>
            {currentPage + 1} of {pageCount || currentPage + 1}
          </strong>
        </Button>
        <Button
          style="buttonOutlined rounded-md p-0.5"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={pageCount <= 1 || currentPage + 1 === pageCount}
        >
          <FiChevronRight size={30} />
        </Button>
        <Button
          style="hidden xl:block buttonOutlined rounded-md p-0.5"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={pageCount <= 1 || currentPage + 1 === pageCount}
        >
          <FiChevronsRight size={30} />
        </Button>
      </div>
      <div className="relative">
        <Dropdown
          title={pageSize}
          onAction={(item) => setPageSize(item)}
          list={[100, 60, 20]}
          buttonStyle="roundedContainer h-8 items-center hover:bg-custom-bg hover:border-white w-[75px]"
          listStyle="zIndexTop roundedContainer w-[75px] absolute bottom-8"
          itemStyle="hover:bg-gray/10 active:bg-gray/10  hover:bg-custom-bg hover:text-cta"
        />
      </div>
    </div>
  );
};

export { Pagination };
