import {
  useState,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { ContentType } from '@typeDef/Shared';

interface Props {
  children: ReactNode;
}

export const ContentContext = createContext<ContentType>(undefined!);

const ContentProvider = ({ children }: Props) => {
  const contentBlurFlag = localStorage.getItem('contentBlurFlag') || '1';

  const contentGrayscaleFlag =
    localStorage.getItem('contentGrayscaleFlag') || '1';

  const [contentBlur, setContentBlur] = useState(contentBlurFlag);

  const [contentGrayscale, setContentGrayscale] =
    useState(contentGrayscaleFlag);

  useEffect(() => {
    localStorage.setItem('contentBlurFlag', contentBlur);
  }, [contentBlur]);

  useEffect(() => {
    localStorage.setItem('contentGrayscaleFlag', contentGrayscale);
  }, [contentGrayscale]);

  const value = useMemo(
    () => ({
      contentBlur,
      setContentBlur,
      contentGrayscale,
      setContentGrayscale
    }),
    [contentBlur, contentGrayscale]
  );

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
};

function useContent() {
  const context = useContext(ContentContext);
  if (context === undefined) {
    // Adding custom error
    throw new Error('Content must be used within a ContentProvider');
  }
  return context;
}

export { ContentProvider, useContent };
