import React, { ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';

type Props = {
  show: boolean;
  close: () => void;
  children: ReactNode;
  dialogStyle?: string;
};

const Dialog = ({
  show,
  close,
  children,
  dialogStyle = 'bg-custom-bg'
}: Props) => {
  if (!show) return null;

  const outerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    close();
  };

  return (
    <div
      onClick={outerClick}
      className="fixed top-0 left-0 right-0 bottom-0 z-[1001] flex justify-center w-full bg-black/50 overflow-auto cursor-default"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`w-1/2 h-fit mt-20 flex flex-col rounded-md px-2 relative ${dialogStyle} `}
      >
        <div className="absolute right-2 top-2 z-[1002]">
          <IoMdClose
            className="cursor-pointer hover:text-cta mt-2 mr-2 p-1 roundedCircle stroke-[30px] bg-custom-bg"
            onClick={close}
            size="30"
          />
        </div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export { Dialog };
