import { ReactNode } from 'react';
import { csColors } from '@src/csColors';

type Variant = {
  color: string;
  backgroundColor: string;
  borderColor: string;
};

type Variants = {
  danger: Variant;
  info: Variant;
};

const Alert = ({
  children,
  variant,
  style
}: {
  children: ReactNode;
  variant: string;
  style?: string;
}) => {
  const variants: Variants = {
    danger: {
      color: csColors.alert.DEFAULT,
      backgroundColor: `${csColors.alert['100']}80`,
      borderColor: `${csColors.alert['100']}30`
    },
    info: {
      color: csColors.info.DEFAULT,
      backgroundColor: csColors.info[100],
      borderColor: `${csColors.info.DEFAULT}20`
    }
  };
  return (
    <div
      className={`rounded-md p-2 border ${style}`}
      style={variants[variant as keyof Variants]}
    >
      {children}
    </div>
  );
};

export { Alert };
