import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useUser } from './UserProvider';
import { useLocation } from 'react-router-dom';

type AnalyticsType = {
  sendEvent: (title: string, ...rest: any) => void;
};

type SaEventFunction = {
  (...args: any[]): void;
};

declare global {
  interface Window {
    sa_event: SaEventFunction;
  }
}

export const AnalyticsContext = createContext<AnalyticsType>(undefined!);

const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const { platform } = useUser();

  const sendEvent = (title: string, ...rest: any) => {
    if (title !== 'Confirm') {
      window.sa_event(title, {
        platform: platform?.name,
        page: pathname,
        ...rest[0]
      });
    }
  };

  const value = useMemo(() => ({ sendEvent }), [pathname, platform?.name]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }
  return context;
}

export { AnalyticsProvider, useAnalytics };
