import { useState, FormEvent, useEffect, ChangeEvent } from 'react';
import { Input } from '@components/shared/Input';
import { PolicyRule, Strategy } from '@api/types';
import { Button } from '@components/shared/Buttons';
import { strategiesIcons } from '@components/Policy/constant';
import { PolicyRuleType } from '@typeDef/Policy';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { useUser } from '@context/UserProvider';
import { Dropdown } from '@components/shared/Dropdown';
import { FaCircleExclamation } from 'react-icons/fa6';

type Props = {
  onAdd: (rule: PolicyRuleType) => void;
  strategies?: Strategy[];
  existingRules?: PolicyRule[] | [];
};

const AddNewRuleForm = ({ onAdd, strategies, existingRules }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>();
  const [error, setError] = useState('');
  const [inputLower, setInputLower] = useState(0);
  const [inputUpper, setInputUpper] = useState(0);
  const [strategyList, setStrategyList] = useState<Strategy[]>(
    strategies || []
  );
  const [search, setSearch] = useState<string>('');
  const [inputRestriction, setInputRestriction] = useState();
  const { domainObjects } = useUser();

  useEffect(() => {
    setError('');
  }, [inputLower, inputUpper, inputRestriction]);

  useEffect(() => {
    const originalList = JSON.parse(JSON.stringify(strategies));
    const filteredList = originalList.filter((strategy: Strategy) => {
      const filteredLabels = strategy.labels?.filter((strategy: string) =>
        strategy.includes(search)
      );
      if (filteredLabels?.length) {
        strategy.labels = filteredLabels;
        return strategy;
      }
    });
    setStrategyList(filteredList);
  }, [search]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (error) return;
    if (!label) {
      setError('Label not set');
      return;
    }
    const contentType =
      inputRestriction === 'none' ? undefined : inputRestriction;
    const ruleExists = existingRules?.some(
      (rule) => rule.label === label && rule.content_type === contentType
    );
    if (ruleExists) {
      setError('Rule already exists');
    } else {
      onAdd({
        lower_threshold: inputLower,
        upper_threshold: inputUpper,
        content_type: contentType,
        label
      });
    }
  };

  const onClick = (label: string) => {
    setError('');
    setLabel(label);
    setIsOpen(false);
  };

  const onOpen = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-4 max-h-[50%]">
      <h2>Add new rule</h2>
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="flex flex-col my-4 max-h-96">
          <label className="text-sm mb-2">Label</label>
          <Button
            onClick={onOpen}
            style={`flex justify-between w-full p-2 border border-neutral-200 rounded-t-md hover:bg-gray/10 items-center ${!isOpen ? 'rounded-b-md' : ''}`}
          >
            <input
              className="w-full py-1 px-2 bg-transparent focus:outline-none"
              required
              type="text"
              value={isOpen ? search : label}
              placeholder="Search.."
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <span>
              {isOpen && <AiOutlineArrowUp />}
              {!isOpen && <AiOutlineArrowDown />}
            </span>
          </Button>
          {isOpen && (
            <div className="border border-neutral-200 rounded-b-lg overflow-y-scroll">
              {strategyList?.map(
                (strategy: Strategy) =>
                  !!strategy.labels?.length && (
                    <div key={strategy.id}>
                      <div className="font-semibold p-3">
                        <img
                          width="20"
                          src={
                            strategiesIcons[strategy.type] ||
                            '/favicon-32x32.png'
                          }
                        />
                        <span className="ml-2">{strategy.name}</span>
                      </div>
                      {strategy?.labels?.map((label: string) => (
                        <div key={`${label}-${strategy.id}`}>
                          <button
                            className="indent-6 bg-custom-bg p-3"
                            onClick={() => onClick(label)}
                          >
                            <span>{label}</span>
                          </button>
                        </div>
                      ))}
                    </div>
                  )
              )}
            </div>
          )}
        </div>
        <div className="flex justify-between mb-3 gap-4">
          <Input
            htmlFor="low"
            label="Queue Content Threshold"
            type="number"
            min="0"
            max={inputUpper}
            step="0.01"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInputLower(parseFloat(e.target.value))
            }
            placeholder="Enter score from 0 to 100"
            required={true}
          />
          <Input
            htmlFor="high"
            label="Automatic Enforcement Threshold"
            type="number"
            min={inputLower}
            max="100"
            step="0.01"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInputUpper(parseFloat(e.target.value))
            }
            placeholder="Enter score from 0 to 100"
            required={true}
          />
        </div>
        {domainObjects && (
          <>
            <div className="text-sm mb-2">Resticted to</div>
            <Dropdown
              onAction={setInputRestriction}
              title={inputRestriction ?? 'none'}
              list={[...domainObjects.map(({ type }) => type), 'none']}
              mainClass="w-[calc(50%-8px)] p-0 bg-custom-bg mb-3"
              buttonStyle="rounded-md border border-neutral-200 p-2 hover:bg-transparent hover:border-neutral-200"
              listStyle="absolute z-40 border bg-custom-bg border-neutral-200 rounded-sm overflow-auto"
              itemStyle="hover:bg-transparent hover:text-cta px-4"
            />
          </>
        )}
        <div className="flex">
          {error && (
            <div className="text-red flex flex-row items-center">
              <div className="flex items-center">
                <FaCircleExclamation className="mr-2" />
              </div>
              <p>{error}</p>
            </div>
          )}
          <div className="flex ml-auto">
            <Button
              onClick={() => null}
              style="bg-cta py-1 hover:bg-cta-hover text-white rounded-md w-36 font-bold"
              hiddenTitle="Add New Rule"
              type="submit"
            >
              Confirm
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { AddNewRuleForm };
