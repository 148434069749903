import { ConfirmationCommons } from '@components/Review/DecisionTree/Confirmation/Commons';

type Props = {
  hideModal: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  showPolicyList: boolean;
};

const ConfirmSkip = ({
  isLoading,
  hideModal,
  onSubmit,
  showPolicyList
}: Props) => {
  return (
    <div>
      <div className="text-xl font-semibold mb-3 pb-2 border-b-2 border-lightgray">
        Skip
      </div>
      <p>You are about to skip this piece of content.</p>
      <ConfirmationCommons
        hideModal={hideModal}
        isLoading={isLoading}
        onSubmit={onSubmit}
        showPolicyList={showPolicyList}
      />
    </div>
  );
};

export { ConfirmSkip };
