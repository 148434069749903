import { useEffect, useState, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiSettings,
  FiLogOut,
  FiList,
  FiChevronsLeft,
  FiChevronsRight,
  FiCompass,
  FiGrid,
  FiHelpCircle,
  FiBox
} from 'react-icons/fi';
import { VscColorMode } from 'react-icons/vsc';
import { MdPolicy } from 'react-icons/md';
import { Auth } from '@aws-amplify/auth';
import { getLogoutURL } from '@utils/user';
import { useUser } from '@context/UserProvider';
import { useQueues } from '@context/QueuesProvider';
import { Dropdown } from '@components/shared/Dropdown';
import { useAnalytics } from '@context/AnalyticsProvider';

type LinkProp = {
  link?: string;
  text: string;
  icon: ReactNode;
  onClick?: () => void;
  style?: string;
  isCollapsed: boolean;
};

const Link = ({
  link = '',
  text,
  icon,
  onClick,
  style,
  isCollapsed
}: LinkProp) => (
  <button onClick={onClick}>
    <NavLink
      to={link}
      className={`flex gap-4 text-md hover:text-[#A5A8FC] ${style}`}
      style={({ isActive }) => ({
        ...(isActive && link && { color: '#A5A8FC' })
      })}
    >
      {icon}
      {!isCollapsed && text}
    </NavLink>
  </button>
);

const QueueItem = ({ queue, isCollapsed }: any) => {
  const { platform } = useUser();
  if (!queue) return null;

  const url = `queues/bulklist/${queue.id}?pid=${platform?.id}`;

  return (
    <NavLink
      to={url}
      className="flex justify-between text-xs py-0"
      style={({ isActive }) => ({
        ...(isActive ? { pointerEvents: 'none', fontWeight: 600 } : null)
      })}
    >
      <div className="line-clamp-1">{!isCollapsed && queue?.name}</div>
      <div style={isCollapsed ? { marginRight: 0 } : { marginRight: 10 }}>
        {queue?.count}
      </div>
    </NavLink>
  );
};

function Sidebar() {
  const { user, platform, platforms, setPlatform, theme, setTheme } = useUser();
  const SIDEBAR_COLLAPSED_KEY = 'sidebarCollapsed';
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(SIDEBAR_COLLAPSED_KEY) === 'true'
  );
  const { mappedQueues } = useQueues();
  const isUserAdmin = user?.isUserAdmin || false;
  const { sendEvent } = useAnalytics();
  const [showCta, setShowCta] = useState<boolean>(false);
  const [bgColor, setBgColor] = useState('bg-custom-bg');

  useEffect(() => {
    localStorage.setItem(SIDEBAR_COLLAPSED_KEY, isCollapsed.toString());
  }, [isCollapsed]);

  const redirectOnSignOut = () => {
    const config = Auth.configure();

    const clientId = config.userPoolWebClientId;

    const url = getLogoutURL(config.oauth, clientId || '');

    window.location.assign(url);
  };

  const signOut = () => {
    Auth.signOut({ global: true }).then(
      () => {
        redirectOnSignOut();
      },
      () => {
        redirectOnSignOut();
      }
    );
  };

  const changeTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    sendEvent('clickEvent', { title: `changed theme to ${newTheme}` });
  };

  const darkerPages = ['/explore', '/dashboard', '/bulklist'];

  const getBgColor = () => {
    const isDarkerPage = darkerPages.some((page) =>
      location.pathname.includes(page)
    );
    return isDarkerPage ? 'bg-default-bg' : 'bg-custom-bg';
  };

  useEffect(() => {
    setBgColor(getBgColor());
  }, [location.pathname, theme]);

  if (!user) return null;

  return (
    <nav
      className={bgColor}
      onMouseEnter={() => setShowCta(true)}
      onMouseLeave={() => setShowCta(false)}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`absolute transitioning top-[153px] ${isCollapsed ? 'ml-[58px]' : 'ml-[266px]'} ${showCta ? 'visible' : 'invisible'} w-[25px] h-[25px] z-[1000] bg-[#6366F1] rounded-xl flex items-center`}
      >
        {isCollapsed ? (
          <FiChevronsRight size="20" color="white" className="ml-[3px]" />
        ) : (
          <FiChevronsLeft size="20" color="white" className="ml-[2px]" />
        )}
      </button>
      <div
        className={`sidebar rounded-r-lg overflow-auto p-[2px] ${isCollapsed && 'w-[72px] align-center'} transitioning flex flex-col h-screen`}
      >
        <div
          className={`bg-[#131313] text-white ml-[-4px] p-6 rounded-r-lg h-screen flex flex-col justify-between overflow-auto`}
        >
          <div className="flex flex-col gap-5">
            <div
              className={`flex gap-4 items-center ${isCollapsed && 'ml-[-7px]'}`}
            >
              <img
                width="40"
                height="40"
                alt="Checkstep"
                src={`/assets/images/logo.svg`}
              />
              {!isCollapsed && (
                <div className="text-xl font-semibold transitioning">
                  checkstep
                </div>
              )}
            </div>

            <div
              className={`h-[35px] zIndexTop mb-2 w-full ${isCollapsed && 'flex items-center'}`}
            >
              {isCollapsed && <FiBox color="white" size="26" />}
              {!isCollapsed && (
                <Dropdown
                  title={platform?.name || ''}
                  list={platforms || []}
                  onAction={setPlatform}
                  listStyle="relative mt-1 overflow-hidden rounded-md"
                  mainClass="bg-[#131313] rounded-md p-0 w-full text-lg text-nowrap"
                  buttonStyle="p-3 font-semibold rounded-sm bg-[#6366F1] text-white hover:bg-[#8185F8] hover:text-white"
                  itemStyle="bg-white text-[#6366F1] p-2 overflow:hidden hover:text-[#8185F8]"
                />
              )}
            </div>

            <div className="flex flex-col gap-5">
              <Link
                link={`/queues?pid=${platform?.id}`}
                text="Queues"
                icon={<FiList size="24" />}
                isCollapsed={isCollapsed}
              />
              <Link
                link={`/explore?pid=${platform?.id}`}
                text="Explore"
                icon={<FiCompass size="24" />}
                isCollapsed={isCollapsed}
              />

              {isUserAdmin && (
                <Link
                  link={`/dashboard?pid=${platform?.id}`}
                  text="Dashboard"
                  icon={<FiGrid size="24" />}
                  isCollapsed={isCollapsed}
                />
              )}
              <Link
                link={`/policies?pid=${platform?.id}`}
                text="Policies"
                icon={<MdPolicy size="24" />}
                isCollapsed={isCollapsed}
              />
            </div>
          </div>

          {!isCollapsed && (
            <div className="sidebarQueue">
              <div className="font-semibold border-b border-[#5D5C5C] pb-1 text-sm">
                Queues
              </div>
              <div className="py-1 flex flex-col gap-1">
                {mappedQueues
                  ?.filter((x) => !!x?.count)
                  .sort((a, b) => (b?.count || 0) - (a?.count || 0))
                  .map((x, index) => (
                    <QueueItem
                      queue={x}
                      key={x?.id || index}
                      isCollapsed={isCollapsed}
                    />
                  ))}
              </div>
            </div>
          )}

          <div
            className={`flex flex-col gap-3 ${isCollapsed && 'itms-center'}`}
          >
            <div className="flex gap-4 hover:text-[#A5A8FC]">
              <button onClick={changeTheme}>
                <VscColorMode size="22" />
              </button>
              {!isCollapsed && (
                <div className="flex justify-between w-full items-center">
                  <div
                    className="cursor-pointer flex w-full justify-between capitalize text-center"
                    onClick={changeTheme}
                  >
                    <span className="text-nowrap text-sm">{theme} Theme</span>
                  </div>
                  <label className="relative cursor-pointer ">
                    <input
                      type="checkbox"
                      checked={theme === 'light'}
                      onChange={changeTheme}
                      className="sr-only peer"
                    />
                    <div className="w-9 h-5 rounded-xl bg-neutral-200 text-cta border-cta border-[1.5px] peer peer-checked:after:translate-x-full peer-checked:after:bg-custom-bg after:bg-cta after:absolute after:top-0 after:start-[0px] after:rounded-xl after:h-5 after:w-5 after:transition-all peer-checked:bg-cta"></div>
                  </label>
                </div>
              )}
            </div>
            <Link
              link={`/settings?pid=${platform?.id}`}
              text="Settings"
              icon={<FiSettings size="22" />}
              style="text-sm"
              isCollapsed={isCollapsed}
            />
            <a
              href="https://checkstep.atlassian.net/servicedesk/customer/portal/1/article/1117487330"
              target="_blank"
              rel="noreferrer"
              className="flex gap-4 hover:text-[#A5A8FC] text-sm"
            >
              <FiHelpCircle size="22" />{' '}
              {!isCollapsed && <span className="">Help</span>}
            </a>

            <Link
              text="Logout"
              icon={<FiLogOut size="22" />}
              onClick={signOut}
              style="text-sm"
              isCollapsed={isCollapsed}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
