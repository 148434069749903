import { ViolationTab } from '@components/Review/Evaluations/ViolationTab';
import { pViolationType } from '@typeDef/Review';

type Props = {
  selectedProperty?: string;
  setSelectedProperty: (val: string) => void;
  evaluations: pViolationType[];
};

const PotentialViolations = ({
  selectedProperty,
  setSelectedProperty,
  evaluations
}: Props) => {
  if (!evaluations) return null;

  return (
    <div className="w-full overflow-visible roundedContainer my-4 p-0 bg-custom-bg">
      {evaluations?.map((x: pViolationType, index: number) => (
        <div key={`${x?.policy}-${index}`}>
          <ViolationTab
            evaluations={x}
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
          />
        </div>
      ))}
    </div>
  );
};

export { PotentialViolations };
