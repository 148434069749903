import { CommunityReport } from '@api/types';
import { MdPolicy } from 'react-icons/md';
import { FaCalendar } from 'react-icons/fa';
import { getValueFromList } from '@components/Review/utils';
import { useUser } from '@context/UserProvider';
import { getLocaleTime } from '@utils/date';
import { TabRow } from '@components/Review/Content/HistoryTabs/TabRow';
import { FaUser } from 'react-icons/fa6';

const Reports = ({ reports }: { reports?: Array<CommunityReport> }) => {
  if (!reports?.length)
    return <div className="ml-1 mt-1">No reports to show</div>;
  const { policies } = useUser();

  const getPolicy = getValueFromList(policies, 'code', 'name');

  const renderReasons = (reason: string) => {
    try {
      const parsed = JSON.parse(reason);
      return (
        <div>
          {parsed.map((x: any) => (
            <div className="indent-2" key={x.msg}>
              <span className="w-24">@{x.user?.username}</span>
              <span className="font-medium break-words">{x.msg}</span>
            </div>
          ))}
        </div>
      );
    } catch {
      return <div className="break-words prose text-dark-text">{reason}</div>;
    }
  };

  return (
    <div className="ml-2">
      {reports?.map((report: CommunityReport) => (
        <div
          key={report.ts.toString()}
          className="py-4 indent-2 border-b-2 border-neutral-200 flex flex-col gap-1 last:border-0"
        >
          <TabRow>
            <FaCalendar size="1rem" className="ml-[2px]" />{' '}
            {getLocaleTime(report.ts.toString())}
          </TabRow>

          {report.cat && (
            <TabRow>
              <MdPolicy size="20" /> {getPolicy(report.cat) ?? report.cat}
            </TabRow>
          )}

          {report.reporter && (
            <TabRow>
              <FaUser size="16" className="ml-[2px]" /> {report.reporter}
            </TabRow>
          )}
          {report.reason && (
            <div className="indent-[2px]">
              <span className="font-semibold">Reason:</span>
              <div className="ml-2">{renderReasons(report.reason)}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export { Reports };
