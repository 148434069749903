import { SubmitButtons } from '@components/shared/Buttons';
import { useState } from 'react';

const NumberField = ({
  setter,
  num,
  type,
  edit
}: {
  setter: (num: number) => void;
  num: number;
  type: string;
  edit: boolean;
}) => (
  <div className="w-full flex flex-row gap-1">
    <div className="p-1 h-10 flex items-center px-2 w-20 overflow-hidden">
      {type}:
    </div>
    <div className="border-[1px] border-neutral-300 p-1 w-full rounded-md flex gap-2 h-10">
      <input
        type="number"
        className="bg-transparent pl-2 w-full focus:outline-none"
        min={0}
        step={1}
        value={num}
        onChange={(e) => setter(parseFloat(e.target.value))}
        disabled={!edit}
      />
    </div>
  </div>
);

const NumberCriteria = ({
  edit,
  setEdit,
  criterion,
  submitEdit,
  keyTo,
  isNewCriteria,
  operation
}: {
  edit: boolean;
  setEdit: (edit: boolean) => void;
  criterion: any;
  submitEdit: (operation: string, changes: any) => void;
  keyTo: string;
  isNewCriteria?: boolean;
  operation: string;
}) => {
  const { include, exclude } = criterion || {};
  const [includeNumber, setIncludeNumber] = useState<number>(include?.[keyTo]);
  const [excludeNumber, setExcludeNumber] = useState<number>(include?.[keyTo]);

  const handleClose = () => {
    setEdit(false);
    setIncludeNumber(include?.[keyTo]);
    setExcludeNumber(exclude?.[keyTo]);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        <NumberField
          edit={edit}
          setter={setIncludeNumber}
          num={includeNumber}
          type={'Include'}
        />
        <NumberField
          edit={edit}
          setter={setExcludeNumber}
          num={excludeNumber}
          type={'Exclude'}
        />
      </div>
      {edit && (
        <div className="w-full flex justify-end mt-3 gap-2">
          <SubmitButtons
            onClose={!isNewCriteria ? handleClose : undefined}
            onSubmit={() =>
              submitEdit(operation, {
                changes: {
                  include: includeNumber,
                  exclude: excludeNumber
                },
                keyName: keyTo
              })
            }
            submitLabel={'Save'}
            isLoading={false}
          />
        </div>
      )}
    </div>
  );
};

export { NumberCriteria };
