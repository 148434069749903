import { useEffect } from 'react';
import { Loader } from '@components/shared/Loader';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import { FiCornerDownLeft } from 'react-icons/fi';
import { Button } from '@components/shared/Buttons';

type Props = {
  hideModal: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  showPolicyList: boolean;
  onQAsubmit?: () => void;
  qa?: boolean;
};

export const message =
  'Are you sure you have finished reviewing this piece of content?';

const ConfirmationCommons = ({
  hideModal,
  isLoading,
  onSubmit,
  showPolicyList,
  onQAsubmit,
  qa
}: Props) => {
  const { enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    enableScope('modal');
    disableScope('actions');
    disableScope('policies');
    disableScope('confirmAction');

    return () => {
      showPolicyList ? enableScope('policies') : enableScope('actions');
      enableScope('confirmAction');
    };
  }, []);

  const onClick = () => {
    if (!qa) return onSubmit;
    if (!onQAsubmit) return onSubmit;
    return onQAsubmit;
  };

  useHotkeys('enter', () => onClick()());
  useHotkeys('esc', () => hideModal());

  return (
    <div>
      <div>
        <p>{message}</p>
      </div>
      <div className="flex gap-2 justify-end mt-4">
        {!isLoading ? (
          <>
            <Button
              style="button bg-cta-disabled flex gap-2 py-2 font-bold text-neutral-900"
              onClick={hideModal}
              hiddenTitle="Close review"
            >
              <kbd>Esc</kbd>
              Close
            </Button>
            <Button
              style="button font-bold py-2 flex gap-2"
              onClick={onClick()}
              hiddenTitle="Confirm review"
            >
              <kbd>
                <FiCornerDownLeft />
              </kbd>
              Confirm
            </Button>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export { ConfirmationCommons };
