import { useEffect } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { assignHotkey } from '@components/Review/utils';
import { Button } from '@components/shared/Buttons';
import { Action } from '@api/types';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';

const Decision = ({ availableActions, setSelectedAction }: any) => {
  const { enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    enableScope('actions');
    return () => {
      disableScope('actions');
    };
  }, []);

  if (!availableActions?.length) return null;

  availableActions?.forEach(
    (action: Action, index: number) =>
      action &&
      assignHotkey((++index).toString(), setSelectedAction, action, 'actions')
  );

  assignHotkey('esc', setSelectedAction, '', 'actions');

  return (
    <div className="relative mt-3 before:absolute before:ml-[0.75rem] before:h-full before:border-l-[2px] before:border-dotted before:border-white before:bg-cta">
      <div className="relative">
        <div className="flex gap-0.5 flex-col">
          {availableActions.map((action: Action, index: number) => (
            <div className="flex justify-between" key={action?.name}>
              <Button
                style="ml-6 flex gap-2 justify-start w-full py-1.5 items-center hover:bg-highlight hover:cursor-pointer"
                onClick={() => setSelectedAction(action)}
                hiddenTitle={`${action?.name} decision selected`}
              >
                <kbd>{++index}</kbd> {action?.name}
              </Button>
              {action?.description && (
                <Tooltip
                  placement="left"
                  text={action?.description}
                  textStyle="w-44 p-2"
                >
                  <FaInfoCircle className="mt-1" />
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Decision };
