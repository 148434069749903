interface MetadataValueProps {
  value?: string;
}

const MetadataValue = ({ value }: MetadataValueProps) => {
  const isLink =
    value && (value.startsWith('http://') || value.startsWith('https://'));

  return isLink ? (
    <a href={value} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  ) : (
    <>{value}</>
  );
};

export { MetadataValue };
