import { useEffect } from 'react';
import PlatformsList from '@components/Settings/PlatformsList';
import { useUser } from '@context/UserProvider';
import { Outlet, useNavigate } from 'react-router-dom';
import { Link } from '@components/shared/Link';

function SettingPage() {
  const { user, platform } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('strategies');
    }
  }, [location.pathname]);

  if (!user) {
    navigate(`/error?pid=${platform?.id}`, {
      state: {
        status: 'nocase',
        title: 'Oops... User not found',
        description: ''
      }
    });
    return;
  }

  return (
    <div className="flex min-h-full bg-custom-bg">
      <div
        className="border-r-[1px] border-border bg-custom-bg"
        style={{ flex: '0 0 276px' }}
      >
        <h4 className="px-4 h-24 flex items-center text-cta">Organisation</h4>
        <div className="flex flex-col">
          <Link to="strategies" title="Strategies" />
          {!!user?.isUserAdmin && <Link to="members" title="Members" />}
          <Link to={`test?pid=${platform?.id}`} title="Test and Integrate" />
          <Link to={`profile?pid=${platform?.id}`} title="Profile" />
          {!!user?.isUserAdmin && <Link to="usage" title="Usage" />}
        </div>
        <PlatformsList />
      </div>
      <div className="overflow-auto w-full min-h-full">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingPage;
