import { ReactNode, useEffect, useState } from 'react';

type TooltipProps = {
  text?: string | ReactNode;
  textStyle?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  children?: ReactNode;
  delay?: number;
};

const Tooltip = ({
  text,
  textStyle,
  placement = 'bottom',
  children,
  delay = 0
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  let timeoutId: NodeJS.Timeout;

  const handleMouseEnter = () => {
    // show the tooltip after delay
    timeoutId = setTimeout(() => setIsVisible(true), delay);
  };

  const handleMouseLeave = () => {
    // hide tooltip
    clearTimeout(timeoutId);
    setIsVisible(false);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutId); // Clean up
  }, []);
  if (!text) return <>{children}</>;

  // Tailwind classes for tooltip placement
  const placements = {
    top: {
      container: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
      arrow:
        'left-1/2 transform -translate-x-1/2 -bottom-2 border-t-black border-l-transparent border-r-transparent border-b-0 border-8'
    },
    right: {
      container: 'left-full top-1/2 transform -translate-y-1/2 ml-2',
      arrow:
        '-left-2 top-1/2 transform -translate-y-1/2 border-r-black border-l-0 border-t-transparent border-b-transparent border-8'
    },
    bottom: {
      container: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
      arrow:
        'left-1/2 transform -translate-x-1/2 -top-2 border-b-black border-l-transparent border-r-transparent border-t-0 border-8'
    },
    left: {
      container: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
      arrow:
        '-right-2 top-1/2 transform -translate-y-1/2 border-l-black border-r-0 border-t-transparent border-b-transparent border-8'
    }
  };

  const { container, arrow } = placements[placement];

  return (
    <div className="relative inline-block">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {isVisible && (
        <div
          className={`absolute z-10 min-w-28 ${textStyle} p-2 text-sm bg-black bg-opacity-95 rounded-sm shadow-md whitespace-normal break-words ${container}`}
        >
          <div className="text-white text-center">{text}</div>
          <div className={`absolute h-0 w-0 ${arrow}`} />
        </div>
      )}
    </div>
  );
};

export { Tooltip };
