const getPercentage = (val: string | number, convert?: boolean) =>
  `${convert ? Number(val) * 100 : val}%`;

const Percentage = ({
  val,
  convert = false
}: {
  val?: number | string;
  convert?: boolean;
}) => {
  if (!val) return 0;
  const value = getPercentage(val, convert);

  return <>{value}</>;
};

export { Percentage, getPercentage };
