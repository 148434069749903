import { useState } from 'react';
import { BsTranslate } from 'react-icons/bs';

const Translate = ({ val }: any) => {
  const [convert, setConvert] = useState(false);

  return (
    <div>
      <BsTranslate
        className="translate_button text-left"
        style={{ top: '-3px', cursor: 'pointer' }}
        onClick={() => setConvert(!convert)}
      />
      <h4 className="translate_button">{convert ? val?.src : val?.tr}</h4>
    </div>
  );
};

export { Translate };
