import { useApiService } from '@api/services';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { ContentCase, CreateInquiryFromSearchRequest } from '@api/types';
import { DateTime } from 'luxon';
import { useUser } from '@context/UserProvider';
import { useAnalytics } from '@context/AnalyticsProvider';
import { capitalizeFirstLetter } from '@utils/string';
import { Dialog } from '@components/shared/Dialog';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

type InquiryModal = {
  isInquiryModalVisible: boolean;
  toggleInquiryModal: () => void;
  clearSelectedEvents?: () => void;
  selectedCS: Map<string, ContentCase>;
  searchQuery?: CreateInquiryFromSearchRequest;
  isSearchQuery?: boolean;
};

const ModalInquiry = ({
  isInquiryModalVisible,
  toggleInquiryModal,
  clearSelectedEvents,
  selectedCS,
  searchQuery,
  isSearchQuery
}: InquiryModal) => {
  const { postInquiries, searchInquiry } = useApiService();
  const [showIds, setShowIds] = useState(false);
  const { platform } = useUser();
  const { sendEvent } = useAnalytics();

  const inquiryFromSearch = () => {
    searchInquiry.mutate(
      {
        ...searchQuery,
        origin: `Search query: ${searchQuery?.q}, ${DateTime.local({
          zone: platform?.timezone
        })}`
      },
      {
        onSuccess: async () => {
          sendEvent('Inquiry from search', { ...searchQuery });
          toggleInquiryModal();
          searchInquiry.reset();
        },
        onError: (err: any) => console.log(err.message)
      }
    );
  };

  const createInquiry = () => {
    postInquiries.mutate(
      {
        cases: [...selectedCS.values()].map((value: ContentCase) => ({
          cid: value?.cid,
          ct: value?.ct
        })),
        origin: `Manual inquiry: ${DateTime.local({
          zone: platform?.timezone
        })}`,
        violations: []
      },
      {
        onSuccess: async () => {
          sendEvent('Inquiry from selection', {
            count: selectedCS.size
          });
          toggleInquiryModal();
          clearSelectedEvents?.();
          postInquiries.reset();
        },
        onError: (err: any) => console.log(err.message)
      }
    );
  };

  const keysToExclude = ['tz', 'q', 'origin', 'pid', 'size'];

  return (
    <Dialog
      show={isInquiryModalVisible}
      close={toggleInquiryModal}
      dialogStyle="w-1/3 bg-custom-bg"
    >
      <div className="border-b border-neutral-300 mb-3 font-semibold text-xl pb-3">
        Open incidents
      </div>
      <div className="mb-3">
        <p className="textMuted mb-3">
          Content will be enqueued into its matching queue for investigation.
          Open incidents on these cases?
        </p>
        <div className="max-h-80 overflow-auto">
          {isSearchQuery && searchQuery?.q ? (
            <>
              {<li>Search Query: {searchQuery.q}</li>}
              {Object.entries(searchQuery)
                .filter(
                  ([key, value]) =>
                    !keysToExclude.includes(key) &&
                    (Array.isArray(value) ? value.length > 0 : value)
                )
                .map(([key, value]) => (
                  <li key={key}>
                    {`${capitalizeFirstLetter(key).replace(/\[\]$/, '')}: ${
                      Array.isArray(value) ? `${value.length} selected` : value
                    }`}
                  </li>
                ))}
            </>
          ) : (
            <>
              <div>Total selected: {selectedCS.size}</div>
              <Button
                onClick={() => setShowIds(!showIds)}
                style="text-neutral-500 text-sm"
                hiddenTitle="showContentIdsInquiry"
              >
                Content ids {showIds ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </Button>
              {showIds && (
                <div>
                  {[...selectedCS.values()].map((value: ContentCase) => (
                    <li className="text-sm text-neutral-500" key={value?.cid}>
                      {value?.ct}: {value?.cid}
                    </li>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="flex gap-2 justify-end">
        <SubmitButtons
          onClose={toggleInquiryModal}
          onSubmit={isSearchQuery ? inquiryFromSearch : createInquiry}
          submitLabel="Confirm"
          isLoading={postInquiries?.isPending}
        />
      </div>
    </Dialog>
  );
};

export { ModalInquiry };
