import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useApiService } from '@api/services';
import { QueuesContextType } from '@typeDef/queues';

interface Props {
  children: ReactNode;
}

export const QueuesContext = createContext<QueuesContextType>(undefined!);

const QueuesProvider = ({ children }: Props) => {
  const { useQueues, useQueuesCount } = useApiService();
  const { data: allQueues, error, isLoading: isLoadingQueues } = useQueues({});
  const { data: queueCount, isPending: isLoadingCount } = useQueuesCount();
  const queues = allQueues?.filter((q) => !q.disabled);
  const archivedQueues = allQueues?.filter((q) => q.disabled);
  const mappedQueues = queues?.map((q) => ({
    ...q,
    ...queueCount?.stats?.[q.id]
  }));
  const value = useMemo(
    () => ({
      queueCount,
      mappedQueues,
      isLoadingCount,
      queues,
      archivedQueues,
      error,
      isLoadingQueues
    }),
    [queueCount, allQueues]
  );

  return (
    <QueuesContext.Provider value={value}>{children}</QueuesContext.Provider>
  );
};

function useQueues() {
  const context = useContext(QueuesContext);
  if (context === undefined) {
    // Adding custom error
    throw new Error('useQueues must be used within a QueuesProvider');
  }
  return context;
}

export { QueuesProvider, useQueues };
