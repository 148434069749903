import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { Platform } from '@api/types';
import { Header } from '@components/shared/Header';
import { FaCheck } from 'react-icons/fa6';
import { Dialog } from '@components/shared/Dialog';
import { SubmitButtons } from '@components/shared/Buttons';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { useQueryClient } from '@tanstack/react-query';

const errorState = {
  title: 'Oops... please try later'
};

const General = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const platformId = searchParams.get('pid-setting') || '';
  const { platforms, platform, user } = useUser();
  const { useEmailNotification } = useApiService();
  const selectedPlatform = platforms?.find(
    (p) => p.id.toString() == platformId
  );

  if (!selectedPlatform) {
    navigate(`/error?pid=${platform?.id}`, {
      state: {
        status: 'nocase',
        title: 'Oops... Platform not found',
        description: ''
      }
    });
    return;
  }

  const { data, error, isLoading } = useEmailNotification({
    platformId: selectedPlatform.id.toString()
  });

  if (isLoading) return <Loader />;
  if (error) return <div>Please try again later</div>;

  return (
    <GeneralForm
      user={user}
      platform={selectedPlatform}
      isEmailEnabled={data?.enabled}
    />
  );
};

type FormProps = {
  platform: Platform;
  isEmailEnabled?: boolean;
  user: any;
};

const GeneralForm = ({ platform, isEmailEnabled, user }: FormProps) => {
  const queryClient = useQueryClient();
  const { postEmailNotification } = useApiService();
  const [show, setShow] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(isEmailEnabled || false);
  const navigate = useNavigate();

  const onSubmit = () => {
    postEmailNotification.mutate(
      {
        payload: { enabled: !isEnabled },
        platformId: platform.id
      },
      {
        onSuccess: async ({ data }) => {
          setShow(false);
          setIsEnabled(data.enabled);
          queryClient.invalidateQueries({
            queryKey: ['getEmailNotification']
          });
        },
        onError: async () => navigate('/error', { state: errorState })
      }
    );
  };

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform?.name}</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">General</h2>
        </div>
      </Header>
      <div className="p-8">
        <h6 className="mt-2">Timezone</h6>
        <div className="roundedContainer pl-2 pb-2 mb-3 mt-3 bg-custom-bg">
          {platform.timezone}
        </div>
      </div>
      <div className="px-8 flex flex-col gap-6">
        <div className="text-xl">Notification settings</div>
        <div className="flex gap-3">
          <button
            onClick={() => setShow(!show)}
            className={`font-medium min-w-[24px] h-[24px] bg-primary-50 border border-primary rounded-sm hover:bg-cta flex items-center justify-center cursor-pointer`}
          >
            {isEnabled && <FaCheck />}
          </button>
          <div>
            Send alerts to my email{' '}
            <span className="text-cta">{user.email}</span> with new incidents.{' '}
          </div>
        </div>
      </div>
      <Dialog
        show={show}
        close={() => setShow(false)}
        dialogStyle="w-1/3 bg-custom-bg relative cursor-default"
      >
        <div className="px-6 pt-2 flex flex-col gap-4">
          <div className="text-xl">Warning</div>
          <div>
            {!isEnabled
              ? 'Please note, you will receive emails when there is something to review on this Platform'
              : 'Please note, you will stop receiving emails when there is something to review on this Platform'}
          </div>
          <div className="flex justify-end gap-3">
            <SubmitButtons
              isLoading={postEmailNotification?.isPending}
              onClose={() => setShow(false)}
              onSubmit={onSubmit}
              submitLabel="Confirm"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default General;
