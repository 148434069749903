import { Button } from '@components/shared/Buttons';
import { get } from '@utils/get';

const SimilarProperty = ({ selectedProperty, event }: any) => (
  <div>
    {selectedProperty &&
      get(
        event,
        `_source.predictions.${selectedProperty}.external.external-multilabels.similar.explanation.data.similar-objects`
      ) && (
        <>
          <h4 className="mb-2 mt-3">Similar Claims</h4>
          <div className="mb-3 flex flex-col w-full gap-1">
            {get(
              event,
              `_source.predictions.${selectedProperty}.external.external-multilabels.similar.explanation.data.similar-objects`
            ).map((e: any) => (
              <Button
                onClick={() => window.open(e.uri, '_blank')}
                key={e.uri}
                style="border-b border-neutral-100 last:border-b-0 py-1 flex items-center"
              >
                <img
                  alt="logo"
                  className="roundedCircle mr-1 min-h-[30px] min-w-[30px]"
                  width={30}
                  height={30}
                  src={e.logo}
                ></img>
                {e.name}
              </Button>
            ))}
          </div>

          {selectedProperty &&
            get(
              event,
              `_source.predictions.${selectedProperty}.text-fact-check`
            ) && (
              <>
                <h5>Similar Claims</h5>
                <div className="mb-3 flex flex-col w-full gap-1">
                  {get(
                    event,
                    `_source.predictions.${selectedProperty}.text-fact-check.fact-checking.score`
                  ) &&
                    get(
                      event,
                      `_source.predictions.${selectedProperty}.text-fact-check.fact-checking.explanation.data.claims`
                    ).map((e: any) => (
                      <div key={e.text} className="py-1">
                        <div
                          style={{
                            borderLeft: '3px solid #474444',
                            borderLeftColor: '#474444',
                            paddingLeft: '.5em'
                          }}
                        >
                          <blockquote className="pb-0 italic text-sm">
                            {get(e, 'claimant') && (
                              <span className="font-medium">
                                {e.claimant}
                                {': '}
                              </span>
                            )}
                            {e.text}
                          </blockquote>
                        </div>

                        <div>
                          {get(e, `claim_reviews`, []).map((r: any) => (
                            <div
                              key={r.url}
                              style={{
                                fontSize: '0.8em',
                                marginTop: '0.5em'
                              }}
                            >
                              <span>
                                <a
                                  href={r.url}
                                  target={'_blank'}
                                  rel="noreferrer"
                                >
                                  {r.publisher_name}
                                </a>
                                {': '}
                              </span>
                              <span>
                                {[
                                  'PolitiFact',
                                  'The Quint',
                                  'Alt News'
                                ].includes(r.publisher_name)
                                  ? r.title.replace(/^(.{55}[^\s]*).*/, '$1...')
                                  : r.textual_rating.replace(
                                      /^(.{55}[^\s]*).*/,
                                      '$1...'
                                    )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
                <div>
                  <small>
                    Provided by:{' '}
                    <a
                      href={`https://toolbox.google.com/factcheck/explorer/search/${encodeURIComponent(
                        get(event._source.content, selectedProperty)
                      )};hl=en`}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      Google FactCheck
                    </a>
                  </small>
                </div>
              </>
            )}
        </>
      )}
  </div>
);

export { SimilarProperty };
