import { ConfirmationCommons } from '@components/Review/DecisionTree/Confirmation/Commons';
import { PolicySelectedList } from '@components/Review/Shared/PolicySelectedList';
import { kebabCaseToWords } from '@utils/string';

type Props = {
  hideModal: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  showPolicyList: boolean;
  actionSelected: any;
  payload: any;
  onQAsubmit?: () => void;
  qa?: boolean;
};

const ConfirmDecision = ({
  actionSelected,
  payload,
  isLoading,
  hideModal,
  onSubmit,
  showPolicyList,
  onQAsubmit,
  qa
}: Props) => (
  <div>
    <div className="text-xl font-semibold mb-3 pb-2 border-b-2 border-lightgray">
      Confirm
    </div>
    <PolicySelectedList payload={payload?.violations}>
      <div className="mb-2">
        {actionSelected?.type && (
          <p>
            You are about to apply the{' '}
            <b>{kebabCaseToWords(actionSelected.name)}</b> enforcement action.
          </p>
        )}
      </div>
    </PolicySelectedList>
    <ConfirmationCommons
      hideModal={hideModal}
      isLoading={isLoading}
      onSubmit={onSubmit}
      showPolicyList={showPolicyList}
      onQAsubmit={onQAsubmit}
      qa={qa}
    />
  </div>
);

export { ConfirmDecision };
