import { useUser } from '@context/UserProvider';
import { Button } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';
import { Header } from '@components/shared/Header';

const TestAndIntegrateHeader = ({ setTest }: any) => {
  const { platform, platforms, domainObjects, domainObject, setDomainObject } =
    useUser();

  if (!platform || !platforms) return null;

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">
            Test and Integrate
          </h2>
        </div>
      </Header>
      <div className="flex justify-between mt-4 px-8">
        <div>
          {domainObject && (
            <Dropdown
              mainClass="rounded-md border-border border align-center flex relative align-center p-2.5"
              buttonStyle="w-28 whitespace-nowrap hover:bg-custom-bg border-border"
              listStyle="absolute zIndexTop rounded-md border border-border top-12 overflow-auto left-0 w-44"
              itemStyle="hover:bg-custom-bg hover:text-cta"
              onAction={(option) => setDomainObject(option.type)}
              title={domainObject.type}
              list={
                domainObjects?.map((_do) => ({ name: _do.type, type: _do })) ??
                []
              }
            />
          )}
        </div>
        <div className="flex gap-3">
          <Button
            title="Manual ingest"
            onClick={() => setTest('manual')}
            style="buttonOutlined border-2"
          />
          <Button
            title="Upload CSV"
            onClick={() => setTest('csv')}
            style="buttonOutlined border-2"
          />
        </div>
      </div>
    </>
  );
};

export { TestAndIntegrateHeader };
