import { useUser } from '@context/UserProvider';
import { getViolationList } from '@components/Review/utils';

const PolicySelectedList = ({ children, payload }: any) => {
  const { policies } = useUser();

  return (
    <div className="pb-2">
      {children}
      {!!payload?.length && (
        <div className="my-2">
          <div className="font-semibold">Policies selected:</div>
          {getViolationList(payload, policies).map((violation: any) => (
            <div
              key={`${violation.field}--${JSON.stringify(violation.policies)}`}
              className="indent-4"
            >
              <div className="font-semibold">{violation?.field}</div>
              <div className="ml-4 my-2">
                {violation.policies.map((y: string) => (
                  <div key={y}>{y}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {!payload?.length && (
        <div className="font-semibold">
          Policies selected: <span className="font-normal">N/A</span>
        </div>
      )}
    </div>
  );
};

export { PolicySelectedList };
