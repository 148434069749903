export const getLogoutURL = (
  { domain, redirectSignIn, responseType }: any,
  clientId: string
) => {
  // The url of the Cognito Hosted UI
  return (
    'https://' +
    domain +
    '/login?redirect_uri=' +
    redirectSignIn +
    '&response_type=' +
    responseType +
    '&client_id=' +
    clientId
  );
};

export const sessionAccessToken = (session: any) => session.idToken.jwtToken;
