import { useNavigate } from 'react-router-dom';
import { FiList } from 'react-icons/fi';
import { Button } from '@components/shared/Buttons';
import { DateTime } from 'luxon';
import { Platform } from '@api/types';

type ageBucket = {
  key: string;
  value: number;
};

const QueueCard = ({
  platform,
  queue,
  title,
  count,
  ageBuckets,
  earliestEvent
}: {
  platform: Platform;
  queue: number;
  title: string;
  count: number;
  ageBuckets: ageBucket[];
  earliestEvent: string;
}) => {
  const navigate = useNavigate();
  const generateDistribution = () =>
    ageBuckets?.map(
      (bucket) =>
        !!bucket.value && (
          <div
            key={bucket.key}
            className={`${getColor(bucket.key)}`}
            style={{ flex: bucket.value ?? 0 }}
          />
        )
    );

  const getColor = (key: string) =>
    ({
      '-12h': 'bg-success',
      '12h-48h': 'bg-alert',
      '48h-': 'bg-error'
    })[key] ?? 'bg-custom-bg';

  return (
    <div
      onClick={() => navigate(`/queues/${queue}/review?pid=${platform?.id}`)}
      className="flex justify-between items-center h-[55px] border-b border-border cursor-pointer"
    >
      <div className="flex items-center">
        <div className="w-[220px] line-clamp-2 mr-2">{title}</div>
        <div className="flex items-center gap-3">
          {ageBuckets && (
            <div className="md:w-[250px] h-[20px] overflow-hidden rounded-xl hidden md:flex gap-[1px]">
              {generateDistribution()}
            </div>
          )}
          <h2 className="w-[110px]">{count}</h2>
        </div>
        <div className="text-base">
          <div className="hidden lg:flex">
            Earliest Content:{' '}
            {DateTime.fromISO(earliestEvent)
              .setZone(platform?.timezone)
              .toFormat('dd LLL HH:mm')}
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <Button
          style="button h-[35px] font-bold"
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/queues/${queue}/review?pid=${platform?.id}`);
          }}
          hiddenTitle="Open single event"
        >
          Review
        </Button>
        <Button
          style="buttonOutlined py-[1px]"
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/queues/bulklist/${queue}?pid=${platform?.id}`);
          }}
          hiddenTitle="Open queue list"
        >
          <FiList size="22" />
        </Button>
      </div>
    </div>
  );
};

export { QueueCard };
