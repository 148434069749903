import { FaCircle } from 'react-icons/fa';

const legends = [
  { text: '48+ hours ago', color: 'text-error' },
  { text: '12-48 hours ago', color: 'text-alert' },
  { text: 'Last 12 hours', color: 'text-success' }
];
const Legend = () => (
  <div className="hidden md:flex items-center gap-3">
    {legends.map((x) => (
      <div key={x.text} className="flex items-center">
        <FaCircle className={`${x.color}`} />
        <span className="ml-1 line-clamp-1">{x.text}</span>
      </div>
    ))}
  </div>
);

export { Legend };
