import { useApiService } from '@api/services';
import { SubmitButtons } from '@components/shared/Buttons';
import { Header } from '@components/shared/Header';
import { Loader } from '@components/shared/Loader';
import { useUser } from '@context/UserProvider';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EuDsaSettings } from '@api/types';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { Checkbox } from '@components/shared/Checkbox';

const Regulations = () => {
  const [searchParams] = useSearchParams();
  const platformId = parseInt(searchParams.get('pid-setting') || '');
  const { useEuApiKey } = useApiService();
  const { data: euDsaSettings, isLoading, error } = useEuApiKey(platformId);

  if (isLoading) return <Loader />;
  if (error || !euDsaSettings)
    return (
      <div className="text-alert m-3">Error: could not retrieve settings</div>
    );

  return (
    <RegulationsForm euDsaSettings={euDsaSettings} platformId={platformId} />
  );
};

type FormProps = {
  euDsaSettings: EuDsaSettings;
  platformId: number;
};

const RegulationsForm = ({ euDsaSettings, platformId }: FormProps) => {
  const queryClient = useQueryClient();
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id == platformId);
  const { register, handleSubmit, reset, formState, watch, setValue } = useForm(
    {
      defaultValues: euDsaSettings
    }
  );
  const { postTransparencyKey } = useApiService();

  useEffect(() => {
    reset(euDsaSettings);
  }, [euDsaSettings]);

  const updateToken = (data: EuDsaSettings) => {
    postTransparencyKey.mutate(
      {
        platformId,
        ...data
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: ['EUAPIKey', platformId]
          });
        },
        onError: (err) => {
          console.log('error', err);
        }
      }
    );
  };

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform?.name}</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Regulations</h2>
        </div>
      </Header>
      <form onSubmit={handleSubmit(updateToken)} className="p-8">
        <h6 className="font-semibold mt-2">Digital Services Act (EU)</h6>
        <div className="mb-3 mt-3">
          For more information on the Digital Services Act, please visit:{' '}
          <a
            href="https://digital-strategy.ec.europa.eu/en/policies/digital-services-act-package"
            target="_blank"
            rel="noopener noreferrer"
            className="link text-primary p-0"
          >
            EU - Digital Services Act
          </a>
        </div>
        <div>API Token</div>
        <input
          type="text"
          placeholder="Enter Transparency DB API Token"
          className="roundedContainer w-full focus:outline-none"
          {...register('token')}
        ></input>

        <div className="mt-3 mb-2">
          You can read more information on how to obtain your DSA API key from
          the European Commission:{' '}
          <a
            href="https://transparency.dsa.ec.europa.eu/page/api-documentation"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary ml-2"
          >
            EU - API Documentation
          </a>
        </div>
        <Checkbox
          title="Use sandbox environment"
          checked={watch('sandbox')}
          onChange={() => setValue('sandbox', !watch('sandbox'))}
        />
        <div className="flex justify-end">
          <SubmitButtons
            onSubmit={() => handleSubmit(updateToken)}
            submitLabel="Save"
            disabled={!formState.isValid}
            isLoading={postTransparencyKey?.isPending}
            submitStyle="font-bold py-2"
          />
        </div>
      </form>
    </>
  );
};

export default Regulations;
