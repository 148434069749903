import { apiClient } from '@api/axios';
import { useEffect } from 'react';
import { useAuth } from '@context/AuthProvider';
import { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const useApiClient = () => {
  const { getToken, auth } = useAuth();

  useEffect(() => {
    const requestIntercept = apiClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const token = await getToken();

        if (!config?.headers?.Authorization) {
          if (config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    const responseIntercept = apiClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: any) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await getToken();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return apiClient(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiClient.interceptors.request.eject(requestIntercept);
      apiClient.interceptors.response.eject(responseIntercept);
    };
  }, [auth]);

  return apiClient;
};

export { useApiClient };
