import { useMemo, Suspense } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Sidebar from '@components/shared/Sidebar';
import * as Sentry from '@sentry/react';
import ErrorFallback from '@containers/ErrorFallback';

function Layout() {
  const location = useLocation();
  const hiddenPaths = ['/organization/new', '/platform/new'];
  const shouldHideScreen = useMemo(
    () => hiddenPaths.includes(location.pathname),
    [location]
  );

  return (
    <>
      {!shouldHideScreen && <Sidebar />}
      <Sentry.ErrorBoundary key={location.pathname} fallback={ErrorFallback}>
        <Suspense fallback={<div>Loading</div>}>
          <Outlet />
        </Suspense>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default Layout;
