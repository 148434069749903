const capitalizeFirstLetter = (string) =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1);

const getFullNameFromEmail = (email) => {
  const nameList = getFullNameAsArray(email);
  return nameList.map((x) => capitalizeFirstLetter(x[0])).join(' ');
};

const getFullNameAsArray = (email) => {
  const emailName = email?.split('@');
  const regex = new RegExp(/[^\W]+/, 'g');
  const nameList = emailName && [...emailName[0].matchAll(regex)];
  return nameList;
};

const addCommasToNumber = (number) => {
  return number?.toLocaleString('en-US', { maximumFractionDigits: 20 });
};

const kebabCaseToWords = (string) => {
  return string
    .split('-')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

const snakeCaseToWords = (string) => {
  return string
    .split('_')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

const camelCaseToWords = (string) => {
  let wordsArray = string.match(/[A-Z][a-z0-9]*/g);
  return wordsArray
    ? wordsArray.map((word) => word.toLowerCase()).join(' ')
    : string;
};

const getModeratorEmail = (id, moderators) => {
  const moderatorDetails = moderators?.find((m) => m.id === id);
  return moderatorDetails ? moderatorDetails.email : id;
};

export {
  capitalizeFirstLetter,
  getFullNameFromEmail,
  kebabCaseToWords,
  camelCaseToWords,
  getFullNameAsArray,
  getModeratorEmail,
  addCommasToNumber,
  snakeCaseToWords
};
