import { ReactNode, createContext, useContext } from 'react';

import { useIsFetching } from '@tanstack/react-query';
interface Props {
  children: ReactNode;
}

export const NotificationContext = createContext<any>(undefined!);

const NotificationProvider = ({ children }: Props) => {
  const isFetching = useIsFetching();

  const value = { isFetching };

  return (
    <NotificationContext.Provider value={value}>
      {isFetching ? (
        <div style={{ right: 0, position: 'absolute' }}>
          Checking updates...
        </div>
      ) : null}
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    // Adding custom error
    throw new Error('Notification must be used within a NotificationProvider');
  }
  return context;
}

export { NotificationProvider, useNotification };
