import { ContentFilterControl } from '@components/shared/ContentFilterControl';
import { HeaderProps } from '@typeDef/Review';
import { Button } from '@components/shared/Buttons';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { HiLink } from 'react-icons/hi';
import { Header } from '@components/shared/Header';

const ReviewHeader = ({
  showControls,
  onSubmit,
  copyCaseLink,
  contentId,
  title
}: HeaderProps) => {
  const copyText = () => navigator.clipboard.writeText(contentId);
  return (
    <Header>
      <div>
        <div className="text-cta font-semibold flex flex-row items-center">
          <div
            className="max-w-[180px] truncate hover:cursor-pointer"
            onClick={copyText}
          >
            {contentId}
          </div>

          <div className="flex gap-2 ml-2">
            {showControls && (
              <Button
                onClick={onSubmit}
                style="button font-bold flex items-center px-1 rounded-1"
                hiddenTitle="Skip review"
                tooltip="Skip review"
              >
                <MdKeyboardDoubleArrowRight />
              </Button>
            )}
            <Button
              onClick={copyCaseLink}
              style="button font-bold flex items-center px-1 rounded-1"
              hiddenTitle="Copy event link"
              tooltip="Copy event link"
            >
              <HiLink />
            </Button>
          </div>
        </div>
        <h2 className="whitespace-nowrap">{title}</h2>
      </div>
      <div>
        <ContentFilterControl />
      </div>
    </Header>
  );
};

export { ReviewHeader };
