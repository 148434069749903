import { useRef, useEffect, useState, DragEvent } from 'react';
import { useApiService } from '@api/services';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Loader } from '@components/shared/Loader';
import { Header } from '@components/shared/Header';
import { Action } from '@api/types';
import { useUser } from '@context/UserProvider';
import { Button } from '@components/shared/Buttons';
import { useQueryClient } from '@tanstack/react-query';
import { Accordion } from '@components/shared/Accordion';
import { FaFolderOpen } from 'react-icons/fa6';
import { ActionField } from '@components/Settings/Actions/ActionField';

const Actions = () => {
  const { platforms } = useUser();
  const { useActions, putActionsPriority, putArchiveAction } = useApiService();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid') || '';
  const platformSection = searchParams.get('pid-setting') || '';
  const platform = platforms?.find((p) => p.id.toString() == platformSection);
  const navigate = useNavigate();
  const dragItem = useRef();
  const [data, setData] = useState<Action[]>();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const isUserAdmin = user?.isUserAdmin;
  const {
    data: actionsList,
    isLoading,
    error
  } = useActions({ platformId: platformSection });

  useEffect(() => {
    if (actionsList) {
      setData(actionsList);
    }
  }, [actionsList]);

  const dragOver = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    if (actionsList?.length) {
      const copyListItems = [...actionsList];

      const originalItem = copyListItems[index];
      const draggedItem = copyListItems[dragItem.current || 0];

      if (originalItem === copyListItems[dragItem.current || 0]) return null;

      const items = copyListItems.filter((item: any) => item != draggedItem);

      items.splice(index, 0, draggedItem);
      setData([...items]);
    }
  };

  const drop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (data) {
      putActionsPriority.mutate(
        {
          platformId: platformSection,
          hints: data.map((action: Action) => action.hint)
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({
              queryKey: ['Actions', platformSection]
            });
          },
          onError: (err) => {
            console.log('error', err);
          }
        }
      );
    }
  };

  const submitArchive = (hint?: string, status?: boolean) => {
    if (hint) {
      putArchiveAction.mutate(
        {
          platformId: platformSection,
          hint: hint,
          archived: !status
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({
              queryKey: ['Actions', platformSection]
            });
          },
          onError: (err) => {
            console.log('error', err);
          }
        }
      );
    }
  };

  const dragStart = (id: any) => {
    dragItem.current = id;
  };

  if (error) return <div>Error</div>;
  if (isLoading) return <Loader />;

  const archived: Action[] = [];
  const active: Action[] = [];
  if (data?.length) {
    for (const action of data) {
      action.archived ? archived.push(action) : active.push(action);
    }
  }

  const actionFieldProps = {
    dragStart,
    drop,
    dragOver,
    isLoading: putArchiveAction?.isPending,
    submitArchive,
    isUserAdmin,
    platformId,
    platformSection
  };

  return (
    <>
      <div className="flex mb-3">
        <Header>
          <div className="flex flex-col pt-2">
            <div className="text-cta font-semibold">{platform?.name}</div>
            <h2 className="mb-3 text-[28px] lg:text-[32px]">Actions</h2>
          </div>
          {!!isUserAdmin && (
            <Button
              title="New Action"
              onClick={() =>
                navigate(
                  `/settings/actions/new?pid=${platformId}&pid-setting=${platformSection}`
                )
              }
              style="button p-3 font-bold"
            />
          )}
        </Header>
      </div>
      <div className="px-8 py-4 line-clamp-1 text-nowrap">
        *Drag and drop to rearrange the priority of the actions.
      </div>
      <div className="mt-4 flex flex-col">
        {!!active?.length &&
          active.map((action: Action, index: number) => (
            <ActionField
              key={action?.hint}
              action={action}
              index={index}
              {...actionFieldProps}
            />
          ))}
        {!!archived?.length && (
          <div className="my-6">
            <Accordion
              title="Archived"
              icon={<FaFolderOpen className="ml-7 h-6 w-6" />}
              hasDivider={false}
            >
              <div className="mt-5">
                {archived.map((action: Action, index: number) => (
                  <ActionField
                    key={action?.hint}
                    action={action}
                    index={index}
                    {...actionFieldProps}
                  />
                ))}
              </div>
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
};

export default Actions;
