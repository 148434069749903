import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaListUl, FaChartLine } from 'react-icons/fa';
import { Button } from '@components/shared/Buttons';
import { Dropdown } from '@components/Dashboard/Dropdown';
import { useUser } from '@context/UserProvider';
import { useQueues } from '@context/QueuesProvider';
import { DatePicker } from '@components/Dashboard/DatePicker';

const Selection = ({ path, icon, text }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isSelected = location.pathname === path;

  return (
    <div>
      <Button
        onClick={() => navigate(`${path}${location.search}`)}
        style={`${isSelected && 'text-cta'} text-lg font-semibold`}
      >
        <span className="flex gap-2">
          {icon} {text}
        </span>
      </Button>
      {isSelected && <div className="border-b border-cta border-2" />}
    </div>
  );
};

const Dashboard = () => {
  const location = useLocation();
  const { user, moderators } = useUser();
  const { queues } = useQueues();
  const [moderatorIds, setModeratorsIds] = useState(new Set<string>());
  const [queueIds, setQueuesIds] = useState(new Set<string>());

  useEffect(() => {
    if (moderators && queues) {
      setModeratorsIds(new Set());
      setQueuesIds(new Set());
    }
  }, [moderators, queues]);

  if (!queues || !moderators) return null;

  return (
    <div className="px-4 py-8 h-full overflow-auto">
      <div className="flex justify-between w-full">
        <div className="flex gap-4 mb-8">
          <Selection
            path="/dashboard"
            text="Overview"
            icon={<FaEye size="22" />}
          />
          <Selection
            path="/dashboard/workflow"
            text="WorkFlow"
            icon={<FaListUl />}
          />
          <Selection
            path="/dashboard/workforce"
            text="WorkForce"
            icon={<FaChartLine />}
          />
        </div>
        <div className="flex flex-row gap-3 h-10">
          {location.pathname.includes('workforce') && (
            <div className="flex gap-3">
              <Dropdown
                title="Moderators"
                isAdmin={user?.isUserAdmin}
                options={
                  moderators?.map((m) => ({ id: m.id, name: m.email })) || []
                }
                onSelect={setModeratorsIds}
                optionsSelected={moderatorIds}
                align="right-0"
              />

              <Dropdown
                title="Queues"
                options={queues}
                onSelect={setQueuesIds}
                optionsSelected={queueIds}
                align="right-0"
              />
            </div>
          )}
          <DatePicker />
        </div>
      </div>
      <Outlet context={{ moderatorIds, queueIds }} />
    </div>
  );
};

export default Dashboard;
