enum Interval {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

interface TimeSeriesPoint {
  time: Date;
  value: number;
  dimensions?: Record<string, any>;
}

interface TimeSeriesData {
  interval: Interval;
  start: Date;
  end?: Date;
  series: TimeSeriesPoint[];
}

interface IntervalSelectorParams {
  interval?: Interval;
  intervals?: Interval[];
  setInterval?: (i: Interval) => void;
  disabled?: boolean;
}

interface TimeSeriesChartParams {
  data?: TimeSeriesData;
  height?: string | number;
  fillColor?: string;
}

export {
  Interval,
  type TimeSeriesPoint,
  type TimeSeriesData,
  type IntervalSelectorParams,
  type TimeSeriesChartParams
};
