import { FiCornerDownLeft } from 'react-icons/fi';
import { useState, useEffect } from 'react';
import { Loader } from '@components/shared/Loader';
import { Dropdown } from '@components/shared/Dropdown';
import { Button } from '@components/shared/Buttons';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import { rootCausesList } from '@src/constants';

type Props = {
  onSubmit: any;
  setModalType: (val: string) => void;
  isLoading: boolean;
};

type Cause = {
  code: string;
  name: string;
};

const ConfirmDisagreement = ({ onSubmit, setModalType, isLoading }: Props) => {
  const { enableScope, disableScope } = useHotkeysContext();
  const [rootCause, setRootCause] = useState<string>();
  const [causeSelected, setCauseSelected] = useState<Cause>();
  const [notes, setNotes] = useState<string>();

  const onSelect = (cause: Cause) => {
    setRootCause(cause.code);
    setCauseSelected(cause);
  };

  const onSubmitClick = () => {
    onSubmit({
      notes,
      passed: false,
      rootCause
    });
  };

  useEffect(() => {
    enableScope('modal');
    disableScope('actions');
    disableScope('policies');
    disableScope('confirmAction');

    return () => enableScope('confirmAction');
  }, []);

  useHotkeys('enter', () => {
    if (rootCause) return onSubmitClick();
  });
  useHotkeys('esc', () => setModalType('qa'));

  return (
    <>
      <div className="text-2xl font-bold mb-4">
        Indicate reason for disagreement
      </div>
      <div>
        <div className="font-semibold mb-2">Select a reason:</div>
        <Dropdown
          title={causeSelected?.name || 'Please select'}
          list={rootCausesList}
          onAction={onSelect}
          mainClass="border border-lightgray rounded-sm"
          buttonStyle="px-3 py-0.5"
          listStyle="absolute z-10 w-[30%] border rounded-md overflow-hidden border-lightgray"
        />
      </div>
      <div className="mt-3">
        <div className="font-semibold mb-3">Moderator notes:</div>
        <textarea
          id="note"
          className="w-full border border-lightgray rounded-md p-2 focus:outline-none"
          name="text"
          rows={6}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        ></textarea>
      </div>
      {!isLoading ? (
        <div className="flex justify-end gap-2 mt-4">
          <Button
            style="buttonOutlined"
            hiddenTitle="Back"
            onClick={() => setModalType('qa')}
          >
            <kbd className="py-0.5">Esc</kbd> Back
          </Button>
          <Button
            style="button"
            hiddenTitle="Confirm QA disagreement"
            disabled={!rootCause}
            onClick={onSubmitClick}
          >
            <kbd className="py-0.5">
              <FiCornerDownLeft />
            </kbd>{' '}
            Confirm
          </Button>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export { ConfirmDisagreement };
