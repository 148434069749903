import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { Policy as PolicyType } from '@api/types';
import { Button } from '@components/shared/Buttons';
import '@components/style.css';
import { Accordion } from '@components/shared/Accordion';
import { FaFolderOpen } from 'react-icons/fa6';

const Policy = () => {
  const navigate = useNavigate();
  const { platform, policies } = useUser();
  const { policyId = '' } = useParams();
  const [itemSelected, setItemSelected] = useState<string>(policyId);

  const selectPolicy = (code: string) => {
    setItemSelected(code);
    navigate(`${code}?pid=${platform?.id}`);
  };

  useEffect(() => {
    if (policies?.length && !policyId) {
      selectPolicy(policies[0].code);
    }
  }, [policies]);

  if (!policies?.length)
    return (
      <div className="displayCenter mt-3 relative top-9">
        <h2>No Policies found. Please select another Platform</h2>
      </div>
    );

  const archived: PolicyType[] = [];
  const active: PolicyType[] = [];

  for (const policy of policies) {
    policy.archived ? archived.push(policy) : active.push(policy);
  }

  return (
    <div className="flex h-screen bg-custom-bg">
      <div
        className="border-r-[2px] border-border pt-[21px] overflow-y-auto"
        style={{ flex: '0 0 276px' }}
      >
        <div>
          <PolicyListItems
            list={active}
            itemSelected={itemSelected}
            selectPolicy={selectPolicy}
          />
          {!!archived?.length && (
            <div className="pr-4 pt-2">
              <Accordion
                title="Archived"
                icon={<FaFolderOpen className="ml-7 h-6 w-6" />}
                hasDivider={false}
              >
                <div className="mt-3">
                  <PolicyListItems
                    list={archived}
                    itemSelected={itemSelected}
                    selectPolicy={selectPolicy}
                  />
                </div>
              </Accordion>
            </div>
          )}
        </div>
      </div>
      <div className="overflow-auto w-full mb-8">
        <Outlet />
      </div>
    </div>
  );
};

const PolicyListItems = ({
  list,
  itemSelected,
  selectPolicy
}: {
  list: PolicyType[];
  itemSelected: string;
  selectPolicy: (code: string) => void;
}) => (
  <>
    {list?.map((item: PolicyType) => (
      <div
        key={item.code}
        className={`${
          itemSelected === item?.code ? 'border-cta-hover' : 'border-custom-bg'
        } border-l-8`}
      >
        <Button
          style={`text-lg py-4 px-5 w-full text-left hover:bg-custom-bg hover:text-cta ${
            itemSelected === item?.code && 'text-cta font-bold'
          }`}
          key={item.code}
          onClick={() => selectPolicy(item?.code)}
        >
          {item.name}
        </Button>
      </div>
    ))}
  </>
);

export default Policy;
