import { useUser } from '@context/UserProvider';
import { DateTime } from 'luxon';

const PlatformTime = ({ dt }: { dt?: DateTime | string | Date }) => {
  const { platform } = useUser();

  if (!dt) return null;

  if (typeof dt === 'string') {
    dt = DateTime.fromISO(dt);
    return <div>{dt.setZone(platform?.timezone).toFormat('ff')}</div>;
  }
};

export { PlatformTime };
