import { useApiClient } from '@hooks/useApiClient';
import {
  TimeSeries_TimeSeriesEvents_,
  TimeSeries_TimeSeriesReviews_,
  ModeratorTimeTrackResponse,
  AnalyticsModeratorMetricsResponse,
  AnalyticsQueueMetricsResponse,
  SummaryMetric,
  OrganizationMember,
  WebhookConfig,
  EvaluationsHistogramResponse,
  PolicyReviewMetricsResponse,
  Policy,
  PolicyHistory,
  QualityMetrics,
  ApiKey,
  AuthorHistory,
  ContentCaseShort,
  EuDsaSettings,
  Action,
  PeriodSummaryReport,
  UsageMetrics,
  SQSConfig,
  TransparencyReport,
  QueueExploreResponse,
  PoliciesMetrics,
  EmailNotificationsConfig,
  GetReviewsTimeSeriesAnalyticsReviewsTimeSeriesGetData,
  GetEventsTimeSeriesAnalyticsEventsTimeSeriesGetData,
  GetReportInTimeForPlatformAnalyticsPlatformReportInTimeGetData,
  QueueMetricsAnalyticsQueuesMetricsGetData,
  ModeratorMetricsAnalyticsModeratorsMetricsGetData,
  PolicyMetricsAnalyticsPoliciesMetricsGetData,
  UsageMetricsAnalyticsUsagesMetricsGetData,
  ModeratorTimeTrackAnalyticsModeratorsTimeTrackGetData,
  GetReportForPlatformAnalyticsPlatformReportGetData,
  ListContentCasesInQueueReviewPlatformsPlatformIdQueuesQueueIdContentCasesGetData,
  CalculateHistogramReviewPlatformsPlatformIdEvaluationsHistogramGetData,
  GetQualityMetricsReviewPlatformsPlatformIdQualityMetricsGetData,
  GetAuthorHistoryReviewPlatformsPlatformIdAuthorsHistoryGetData,
  GetSiblingsReviewPlatformsPlatformIdContentCasesSiblingsGetData,
  TransparencyReportAnalyticsTransparencyReportGetData
} from '@api/types';
import {
  PlatformReportsParams,
  PlatformReportsInTimeParams,
  ModeratorMetricsParams,
  QueueMetricsParams,
  ReviewsParams,
  ModeratorTimeTrackParams,
  EventsApiParam,
  PoliciesBreakdownMetricsParams,
  QualityMetricsParams,
  StrategyExt
} from '@typeDef/Apis';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '@context/UserProvider';
import { useQueuesApi } from '@api/services/queues';
import { useContentCaseApi } from '@api/services/contentCase';
import { useMutationApi } from '@api/services/mutations';

const useApiService = () => {
  const apiClient = useApiClient();
  const { platform, user } = useUser();

  const useMembers = () =>
    user?.role === 'administrator'
      ? useQuery({
          queryKey: ['members'],
          queryFn: async () => {
            const { data } = await apiClient.get<OrganizationMember[]>(
              `/organizations/members`
            );
            return data;
          }
        })
      : { data: [], error: null, isLoading: false, isSuccess: true };

  const useStrategies = () =>
    useQuery({
      queryKey: ['strategies'],
      queryFn: async () => {
        const { data } = await apiClient.get<StrategyExt[]>(
          `/organizations/strategies`
        );
        return data;
      },
      placeholderData: (previousData) => previousData
    });

  const useReportInTime = ({
    interval,
    startDate,
    endDate
  }: PlatformReportsInTimeParams) => {
    return useQuery({
      queryKey: [
        'reportsInTime',
        platform?.id,
        interval,
        startDate?.toISODate(),
        endDate?.toISODate()
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<PeriodSummaryReport[]>(
          `statistics/platform/report/in-time`,
          {
            params: {
              startDate: startDate?.toISODate(),
              endDate: endDate?.toISODate(),
              platformId: platform?.id,
              tz: platform?.timezone,
              interval
            } as GetReportInTimeForPlatformAnalyticsPlatformReportInTimeGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id
    });
  };

  const useEvents = ({ interval, startDate, endDate }: EventsApiParam) => {
    return useQuery({
      queryKey: [
        'events',
        platform?.id,
        interval,
        startDate?.toISODate(),
        endDate?.toISODate()
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<TimeSeries_TimeSeriesEvents_>(
          `statistics/events/time-series`,
          {
            params: {
              startDate: startDate?.toISODate(),
              endDate: endDate?.toISODate(),
              platformId: platform?.id,
              tz: platform?.timezone,
              interval: interval
            } as GetEventsTimeSeriesAnalyticsEventsTimeSeriesGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate
    });
  };

  const useReviews = ({ interval, startDate, endDate }: ReviewsParams) =>
    useQuery({
      queryKey: [
        'reviews',
        platform?.id,
        interval,
        startDate?.toISODate(),
        endDate?.toISODate()
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<TimeSeries_TimeSeriesReviews_>(
          `statistics/reviews/time-series`,
          {
            params: {
              startDate: startDate?.toISODate(),
              endDate: endDate?.toISODate(),
              platformId: platform?.id,
              tz: platform?.timezone,
              interval: interval
            } as GetReviewsTimeSeriesAnalyticsReviewsTimeSeriesGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate
    });

  const useQueueBreakdownMetrics = ({
    startDate,
    endDate,
    queueIds,
    moderatorIds
  }: QueueMetricsParams) =>
    useQuery({
      queryKey: [
        'queueMetrics',
        platform?.id,
        startDate,
        endDate,
        queueIds,
        moderatorIds
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<AnalyticsQueueMetricsResponse>(
          `/statistics/queues/metrics`,
          {
            params: {
              startDate: startDate.toISODate(),
              endDate: endDate.toISODate(),
              platformId: platform?.id,
              tz: platform?.timezone,
              ...(queueIds.length > 0 && { queueIds: queueIds.join(',') }),
              ...(moderatorIds.length > 0 && {
                moderatorIds: moderatorIds.join(',')
              })
            } as QueueMetricsAnalyticsQueuesMetricsGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate
    });

  const useModeratorBreakdownMetrics = ({
    startDate,
    endDate,
    queueIds,
    moderatorIds
  }: ModeratorMetricsParams) =>
    useQuery({
      queryKey: [
        'moderatorMetrics',
        platform?.id,
        startDate,
        endDate,
        queueIds,
        moderatorIds
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<AnalyticsModeratorMetricsResponse>(
          `/statistics/moderators/metrics`,
          {
            params: {
              platformId: platform?.id,
              startDate: startDate.toISODate(),
              endDate: endDate.toISODate(),
              tz: platform?.timezone,
              ...(queueIds.length > 0 && { queueIds: queueIds.join(',') }),
              ...(moderatorIds.length > 0 && {
                moderatorIds: moderatorIds.join(',')
              })
            } as ModeratorMetricsAnalyticsModeratorsMetricsGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate
    });

  const usePoliciesBreakdownMetrics = ({
    startDate,
    endDate
  }: PoliciesBreakdownMetricsParams) =>
    useQuery({
      queryKey: [
        'policiesMetrics',
        platform?.timezone,
        startDate?.toISODate(),
        endDate?.toISODate(),
        platform?.id
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<PoliciesMetrics>(
          `/statistics/policies/metrics`,
          {
            params: {
              platformId: platform?.id,
              start: startDate?.toISODate(),
              end: endDate?.toISODate(),
              tz: platform?.timezone ?? 'UTC',
              v: 1
            } as PolicyMetricsAnalyticsPoliciesMetricsGetData['query']
          }
        );
        return data;
      },
      enabled:
        !!platform?.id && !!startDate && !!endDate && !!platform?.timezone
    });

  const useUsageMetrics = () =>
    useQuery({
      queryKey: ['usagesMetrics', platform?.timezone],
      queryFn: async () => {
        const { data } = await apiClient.get<UsageMetrics>(
          `/statistics/usages/metrics`,
          {
            params: {
              tz: platform?.timezone ?? 'UTC'
            } as UsageMetricsAnalyticsUsagesMetricsGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.timezone
    });

  const useModeratorsTimeTrack = ({
    startDate,
    endDate,
    queueIds,
    moderatorIds
  }: ModeratorTimeTrackParams) =>
    useQuery({
      queryKey: [
        'moderatorsTimeTrack',
        platform?.id,
        startDate?.toISODate(),
        endDate?.toISODate(),
        queueIds,
        moderatorIds
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<ModeratorTimeTrackResponse>(
          `statistics/moderators/time-track`,
          {
            params: {
              ...(queueIds.length > 0 && { queueIds: queueIds.join(',') }),
              ...(moderatorIds.length > 0 && {
                moderatorIds: moderatorIds.join(',')
              }),
              startDate: startDate?.toISODate(),
              endDate: endDate?.toISODate(),
              platformId: platform?.id,
              tz: platform?.timezone
            } as ModeratorTimeTrackAnalyticsModeratorsTimeTrackGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate
    });

  const useReport = ({ startDate, endDate }: PlatformReportsParams) => {
    return useQuery({
      queryKey: [
        'reports',
        platform?.id,
        startDate?.toISODate(),
        endDate?.toISODate()
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<SummaryMetric[]>(
          `statistics/platform/report`,
          {
            params: {
              startDate: startDate?.toISODate(),
              endDate: endDate?.toISODate(),
              platformId: platform?.id,
              tz: platform?.timezone
            } as GetReportForPlatformAnalyticsPlatformReportGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate
    });
  };

  const useQueueBulkList = ({ queueId, start, size }: any) =>
    useQuery({
      queryKey: ['useQueueBulkList', queueId, start, size, platform?.id],
      queryFn: async () => {
        const { data } = await apiClient.get<QueueExploreResponse>(
          `review/platforms/${platform?.id}/queues/${queueId}/content-cases`,
          {
            params: {
              start,
              size
            } as ListContentCasesInQueueReviewPlatformsPlatformIdQueuesQueueIdContentCasesGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!queueId && !!size,
      placeholderData: (previousData) => previousData
    });

  const useActions = ({ platformId }: { platformId: string }) =>
    useQuery({
      queryKey: ['settingsActions', platformId],
      queryFn: async () => {
        const { data } = await apiClient.get<Action[]>(
          `platforms/${platformId || platform?.id}/actions`
        );
        return data;
      },
      enabled: !!platform?.id
    });

  const useWebhook = ({ platformId }: { platformId: string }) =>
    useQuery({
      queryKey: ['useWebhook', platformId],
      queryFn: async () => {
        const { data } = await apiClient.get<WebhookConfig>(
          `platforms/${platformId}/outbound/webhook`
        );
        return data;
      },
      enabled: !!platformId
    });

  const useSqs = ({ platformId }: { platformId: string }) =>
    useQuery({
      queryKey: ['useSqs', platformId],
      queryFn: async () => {
        const { data } = await apiClient.get<SQSConfig>(
          `platforms/${platformId}/outbound/sqs`
        );
        return data;
      },
      enabled: !!platformId
    });

  const useApiKeys = ({ platformId }: { platformId: string }) =>
    useQuery({
      queryKey: ['useApiKeys', platformId],
      queryFn: async () => {
        const { data } = await apiClient.get<ApiKey[]>(
          `platforms/${platformId}/api-keys`
        );
        return data;
      },
      enabled: !!platformId
    });

  const usePolicyMetrics = ({ code }: { code: string }) => {
    return useQuery({
      queryKey: ['PolicyMetrics', code],
      queryFn: async () => {
        const { data } = await apiClient.get<PolicyReviewMetricsResponse>(
          `/review/platforms/${platform?.id}/policies/${code}/metrics`
        );
        return data;
      },
      enabled: !!platform?.id && !!code
    });
  };

  const useEvaluationsHistogram = ({
    label,
    field,
    strategy,
    contentType
  }: {
    label?: string;
    field?: string;
    strategy?: string;
    contentType?: string;
  }) => {
    return useQuery({
      queryKey: ['EvaluationsHistogram', label, field, strategy, contentType],
      queryFn: async () => {
        const { data } = await apiClient.get<EvaluationsHistogramResponse>(
          `/review/platforms/${platform?.id}/evaluations/histogram`,
          {
            params: {
              label,
              field,
              strategy,
              contentType
            } as CalculateHistogramReviewPlatformsPlatformIdEvaluationsHistogramGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!label
    });
  };

  const usePolicy = ({ code }: { code: string }) => {
    return useQuery({
      queryKey: ['Policy', code, platform?.id],
      queryFn: async () => {
        const { data } = await apiClient.get<Policy>(
          `/platforms/${platform?.id}/policies/${code}`
        );
        return data;
      },
      enabled: !!platform?.id && !!code
    });
  };

  const usePolicyHistory = ({ code }: { code: string }) => {
    return useQuery({
      queryKey: ['PolicyHistory', code, platform?.id],
      queryFn: async () => {
        const { data } = await apiClient.get<PolicyHistory[]>(
          `/platforms/${platform?.id}/policies/${code}/rules/history`
        );
        return data;
      },
      enabled: !!platform?.id && !!code
    });
  };

  const useQualityMetrics = ({ startDate, endDate }: QualityMetricsParams) => {
    return useQuery({
      queryKey: ['QualityMetrics', platform?.id, startDate, endDate],
      queryFn: async () => {
        const { data } = await apiClient.get<QualityMetrics>(
          `/review/platforms/${platform?.id}/quality/metrics`,
          {
            params: {
              tz: platform?.timezone,
              startDate,
              endDate
            } as GetQualityMetricsReviewPlatformsPlatformIdQualityMetricsGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!startDate && !!endDate,
      placeholderData: (previousData) => previousData
    });
  };

  const useAuthorHistory = ({ authorId }: { authorId: string }) => {
    return useQuery({
      queryKey: ['AuthorHistory', authorId],
      queryFn: async () => {
        const { data } = await apiClient.get<AuthorHistory>(
          `review/platforms/${platform?.id}/authors/history`,
          {
            params: {
              author_id: authorId
            } as GetAuthorHistoryReviewPlatformsPlatformIdAuthorsHistoryGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!authorId
    });
  };

  const useSiblingsBefore = ({
    complex_type,
    content_id,
    before
  }: {
    complex_type?: string;
    content_id: string;
    before: number;
  }) => {
    return useQuery({
      queryKey: ['SiblingsBefore', complex_type, content_id, before],
      queryFn: async () => {
        const { data } = await apiClient.get<ContentCaseShort[]>(
          `review/platforms/${platform?.id}/content-cases/siblings`,
          {
            params: {
              complex_type,
              content_id,
              before
            } as GetSiblingsReviewPlatformsPlatformIdContentCasesSiblingsGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!complex_type && !!content_id,
      placeholderData: (previousData) => previousData
    });
  };

  const useSiblingsAfter = ({
    complex_type,
    content_id,
    after
  }: {
    complex_type?: string;
    content_id: string;
    after: number;
  }) => {
    return useQuery({
      queryKey: ['SiblingsAfter', complex_type, content_id, after],
      queryFn: async () => {
        const { data } = await apiClient.get<ContentCaseShort[]>(
          `review/platforms/${platform?.id}/content-cases/siblings`,
          {
            params: {
              complex_type,
              content_id,
              after
            } as GetSiblingsReviewPlatformsPlatformIdContentCasesSiblingsGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!complex_type && !!content_id,
      placeholderData: (previousData) => previousData
    });
  };

  const useEuApiKey = (platformId: number) => {
    return useQuery({
      queryKey: ['EUAPIKey', platformId],
      queryFn: async () => {
        const { data } = await apiClient.get<EuDsaSettings>(
          `platforms/${platformId}/transparency/token`
        );
        return data;
      },
      enabled: !!platformId
    });
  };

  const useTransparencyMetrics = ({
    start,
    end
  }: {
    start: string | null;
    end: string | null;
  }) => {
    return useQuery({
      queryKey: [
        'TransparencyMetrics',
        platform?.id,
        platform?.timezone,
        start,
        end
      ],
      queryFn: async () => {
        const { data } = await apiClient.get<TransparencyReport>(
          `/statistics/transparency/report`,
          {
            params: {
              tz: platform?.timezone,
              platformId: platform?.id,
              start,
              end
            } as TransparencyReportAnalyticsTransparencyReportGetData['query']
          }
        );
        return data;
      },
      enabled: !!platform?.id && !!platform?.timezone && !!start && !!end
    });
  };

  const useEmailNotification = ({ platformId }: { platformId: string }) => {
    return useQuery({
      queryKey: ['getEmailNotification'],
      queryFn: async () => {
        const { data } = await apiClient.get<EmailNotificationsConfig>(
          `/platforms/${platformId}/outbound/email-notifications`
        );
        return data;
      }
    });
  };
  return {
    ...useQueuesApi(),
    ...useMutationApi(),
    ...useContentCaseApi(),
    useMembers,
    useStrategies,
    useReportInTime,
    useEvents,
    useReviews,
    useModeratorsTimeTrack,
    useModeratorBreakdownMetrics,
    useQueueBreakdownMetrics,
    useUsageMetrics,
    useReport,
    usePoliciesBreakdownMetrics,
    useQueueBulkList,
    useActions,
    useWebhook,
    useSqs,
    useApiKeys,
    usePolicyMetrics,
    useEvaluationsHistogram,
    usePolicy,
    usePolicyHistory,
    useQualityMetrics,
    useAuthorHistory,
    useSiblingsBefore,
    useSiblingsAfter,
    useEuApiKey,
    useTransparencyMetrics,
    useEmailNotification
  };
};

export { useApiService };
