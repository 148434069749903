import { FiCopy } from 'react-icons/fi';

const CellWithCopy = ({ val, width }: { val: string; width?: string }) => {
  const copyCUrl = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(val);
  };

  return (
    <div className="flex cursor-pointer">
      <span className={`table-cell-with-copy ${width}`}>{val}</span>
      <FiCopy onClick={copyCUrl} className="cursor-pointer hover:text-cta" />
    </div>
  );
};

export { CellWithCopy };
