import { Button, SubmitButtons } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';
import { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaEllipsis } from 'react-icons/fa6';
import { Dialog } from '@components/shared/Dialog';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { FaMinusCircle } from 'react-icons/fa';
import { IoIosAddCircle } from 'react-icons/io';
import { Queue } from '@api/types';
import { snakeCaseToWords } from '@utils/string';

const InputMethod = (props: any) => {
  const { criteriaMapping, operation } = props;
  const Component = criteriaMapping[operation]?.component;
  if (!Component) {
    return null;
  }
  return <Component {...props} key={operation} />;
};

const CriteriaStatus = ({
  pendingStatus,
  submit
}: {
  pendingStatus?: string;
  submit: () => any;
}) => {
  if (!pendingStatus) return null;

  const statusConfig: {
    [key: string]: { icon: JSX.Element; hiddenTitle: string; color: string };
  } = {
    added: {
      icon: <IoIosAddCircle size="28" color="#38D900" />,
      hiddenTitle: 'discarded add criteria',
      color: '#38D900'
    },
    edited: {
      icon: <AiFillExclamationCircle size="24" color="#FCA701" />,
      hiddenTitle: 'discarded edit criteria',
      color: '#FCA701'
    },
    deleted: {
      icon: <FaMinusCircle size="22" color="red" />,
      hiddenTitle: 'discarded delete criteria',
      color: 'red'
    }
  };

  const status = statusConfig[pendingStatus];

  if (!status) return null;

  return (
    <Button
      onClick={submit}
      hiddenTitle={status.hiddenTitle}
      tooltip="Click to Undo the pending change"
      tooltipStyle="w-28"
      style="hover:bg-transparent"
    >
      {status.icon}
    </Button>
  );
};

const QueueCriteria = ({
  criterion,
  submitAsPending,
  isNewCriteria,
  queues,
  criteriaMapping
}: {
  criterion: any;
  submitAsPending: any;
  isNewCriteria?: boolean;
  queues: Queue[];
  criteriaMapping: any;
}) => {
  const { op: operation } = criterion;
  const [open, setOpen] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(isNewCriteria || false);
  const [pendingStatus, setPendingStatus] = useState(
    criterion?.new ? 'added' : ''
  );

  const inputType = criteriaMapping[operation]?.render;
  const inputData = criteriaMapping[operation]?.listData;
  const keyTo = criteriaMapping[operation]?.payloadKey;
  const singleVal = criteriaMapping[operation]?.singleValue;

  const handleEdit = (option: any) => {
    if (option === 'Edit') {
      setEdit(true);
      setOpen(true);
    }
    if (option === 'Delete') {
      setShowDelete(true);
    }
  };

  useEffect(() => {
    if (!criterion?.new) setPendingStatus('');
  }, [criterion?.new]);

  const submitEdit = (operation: string, changes: any) => {
    submitAsPending(operation, {
      ...changes,
      new: criterion?.new || isNewCriteria
    });
    setPendingStatus('edited');
    setEdit(false);
  };

  const inputProps = {
    criterion,
    edit,
    setEdit,
    submitEdit,
    keyTo,
    inputType,
    isNewCriteria,
    queues,
    inputData,
    singleVal,
    criteriaMapping,
    operation
  };

  return (
    <>
      {!isNewCriteria && (
        <div
          className={`flex flex-row justify-between border-b border-border px-3 py-2 w-full overflow-visible ${open ? 'border-none' : 'border-b'} cursor-pointer`}
          onClick={() => setOpen(!open)}
          data-testid={`criteria--edit`}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setOpen(!open);
            }
          }}
        >
          <div
            className={`flex items-center gap-2 line-clamp-1 font-semibold text-md overflow-visible ${open ? 'line-clamp-2' : ''}`}
          >
            <CriteriaStatus
              pendingStatus={pendingStatus}
              submit={() => {
                submitAsPending(operation, { removePending: true });
                setPendingStatus('');
              }}
            />
            {operation && snakeCaseToWords(operation)}
          </div>
          <div className="flex flex-row gap-3 relative">
            <Dropdown
              buttonStyle="border rounded-[6px] px-1 hover:bg-transparent hover:border-neutral-200 flex justify-center items-center"
              listStyle="absolute z-40 border bg-custom-bg border-border w-24 rounded-sm overflow-auto"
              itemStyle="hover:bg-transparent hover:text-cta"
              title={<FaEllipsis size={15} />}
              list={['Edit', 'Delete']}
              onAction={handleEdit}
              arrow={false}
            />

            {!open ? <FaChevronDown size={15} /> : <FaChevronUp size={15} />}
          </div>
        </div>
      )}
      {(open || isNewCriteria) && (
        <div
          className={`pt-2 flex flex-col w-full cursor-default overflow-visible ${isNewCriteria ? '' : 'px-2'}`}
        >
          <div className="flex flex-row gap-2 items-start w-full overflow-visible">
            <InputMethod {...inputProps} />
          </div>
        </div>
      )}
      <Dialog show={showDelete} close={() => setShowDelete(false)}>
        <h6>Are you sure?</h6>
        <div className="my-2">You are about to delete {operation}</div>
        <div className="flex justify-end gap-2">
          <SubmitButtons
            onSubmit={() => {
              submitEdit(operation, { [keyTo]: undefined, remove: true });
              setPendingStatus('deleted');
              setShowDelete(false);
            }}
            submitLabel={'Confirm'}
            onClose={() => null}
            isLoading={false}
          />
        </div>
      </Dialog>
    </>
  );
};

export default QueueCriteria;
