import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Amplify from '@aws-amplify/auth';
import App from './App';
import './index.css';

Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env
      .VITE_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,

    oauth: {
      domain: import.meta.env.VITE_AWS_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: import.meta.env.VITE_AWS_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: import.meta.env.VITE_AWS_COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code'
    }
  }
});

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration()
    ],
    environment: import.meta.env.VITE_SENTRY_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
