import { Dropdown } from '@components/shared/Dropdown';

type ChartIntervalProps = {
  title: string;
  interval: string;
  intervals: string[];
  setInterval: (val: string) => void;
};

const ChartInterval = ({
  title,
  interval,
  intervals,
  setInterval
}: ChartIntervalProps) => (
  <div className="chartIntervalContainer">
    <h4>{title}</h4>
    <Dropdown
      title={`Per ${interval}`}
      list={intervals}
      onAction={setInterval}
      mainClass="p-0 max-w-max bg-custom-bg"
      buttonStyle="rounded-sm border border-neutral-200 p-2 hover:bg-transparent hover:border-neutral-200 h-8 flex justify-center items-center"
      listStyle="absolute z-40 border bg-custom-bg border-neutral-200 w-24 rounded-sm overflow-auto"
      itemStyle="hover:bg-transparent hover:text-cta"
    />
  </div>
);

export { ChartInterval };
