import { useState } from 'react';
import { ContentField, MetadataElement } from '@api/types';
import { ContentImage } from '@components/shared/Content/ContentImage';
import { HiOutlineDocumentRemove } from 'react-icons/hi';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { Dialog } from '@components/shared/Dialog';
import { TextContent } from '@components/shared/Content/TextContent';
import { VideoPlayer } from '@components/shared/Content/Video';
import { MetadataValue } from '@components/shared/Content/MetadataValue';

type PropTypes = {
  val: string;
  width?: string;
  onClick?: () => void;
  isSelectable: boolean;
};

const FieldTypes = {
  text: ({ val, isSelectable }: PropTypes) => (
    <TextContent val={val} isSelectable={isSelectable} />
  ),
  image: ({ val, width = '300' }: PropTypes) => (
    <ContentImage key={val} content={val} width={width} />
  ),
  video: ({ val, width = '300' }: PropTypes) => (
    <VideoPlayer key={val} width={width} url={val} />
  ),
  uri: ({ val }: PropTypes) => (
    <div key={val} className="p-2">
      {val}
    </div>
  ),
  file: ({ val }: PropTypes) => (
    <div className="p-2 flex gap-3 w-full justify-center">
      <a
        target="_blank"
        href={val}
        rel="noreferrer"
        className="button break-words font-bold"
      >
        View file
      </a>
    </div>
  ),
  audio: ({ val }: PropTypes) => (
    <audio controls id={val} src={val} preload="auto" />
  )
} as any;

type FieldProp = {
  field: ContentField;
  fieldId?: string;
  width?: string;
  selectedProperty?: string;
  setSelectedProperty?: (val: string) => void;
};
const FieldRenderer = ({
  field,
  fieldId,
  width,
  selectedProperty,
  setSelectedProperty
}: FieldProp) => {
  const [show, setShow] = useState<boolean>(false);
  const onClick = () => setShow(true);

  if (!field) return null;

  const onSelect = () => {
    if (setSelectedProperty && fieldId) setSelectedProperty(fieldId);
  };
  const isSelectable = selectedProperty && setSelectedProperty;

  return (
    <>
      <Tooltip
        text={fieldId ?? field.id}
        delay={isSelectable ? 1 : 1000}
        key={field.src}
      >
        <div
          className={`my-1 w-full ${isSelectable ? 'contentOverlay' : ''} justify-center ${
            fieldId == selectedProperty ? 'active' : ''
          } ${
            field.type == 'image' || field.type == 'video'
              ? 'flex'
              : 'text-center'
          }`}
          onMouseEnter={onSelect}
          onClick={(e) => e.stopPropagation()}
        >
          {FieldTypes[field.type]({
            val: field.src,
            width,
            onClick,
            isSelectable
          })}
          {!!field.metadata?.length && (
            <div className="text-xs ml-2 mb-4 text-left">
              {field.metadata?.map((meta: MetadataElement) => (
                <span key={`${fieldId}-${meta.value}`} className="mr-2">
                  [<span className="font-bold mr-1">{meta.id}:</span>
                  <span>
                    <MetadataValue value={meta.value} />]
                  </span>
                </span>
              ))}
            </div>
          )}
        </div>
      </Tooltip>
      <Dialog show={show} close={() => setShow(false)}>
        <embed id={field.src} src={field.src} className="w-full h-[80vh]" />
      </Dialog>
    </>
  );
};

type Props = {
  fields?: ContentField[];
  selectedProperty?: string;
  setSelectedProperty?: (val: string) => void;
  parentId?: string;
};

const ContentFields = ({
  fields = [],
  selectedProperty,
  setSelectedProperty,
  parentId
}: Props) => {
  if (!fields?.length)
    return (
      <div className="text-center my-4">
        <HiOutlineDocumentRemove size={40} />
        <h5 className="mt-2">No contents to display</h5>
      </div>
    );

  const width = '100%';

  return (
    <>
      {fields.map((field: ContentField) => {
        const fieldId = parentId ? `${parentId}.${field.id}` : field.id;
        return (
          <div key={field.id}>
            <FieldRenderer
              field={field}
              fieldId={fieldId}
              width={width}
              selectedProperty={selectedProperty}
              setSelectedProperty={setSelectedProperty}
            />
            {field.fields && (
              <ContentFields
                fields={field.fields}
                selectedProperty={selectedProperty}
                setSelectedProperty={setSelectedProperty}
                parentId={field.id}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export { ContentFields };
