import { Inquiry } from '@api/types';
import { FaCalendar, FaUserAlt, FaInfoCircle } from 'react-icons/fa';
import { getModeratorEmail } from '@utils/string';
import { getLocaleTime } from '@utils/date';
import { useUser } from '@context/UserProvider';
import { TabRow } from '@components/Review/Content/HistoryTabs/TabRow';

const Inquiries = ({ inquiries }: { inquiries?: Array<Inquiry> }) => {
  if (!inquiries?.length)
    return <div className="ml-1 mt-1">No inquiries to show</div>;
  const { moderators } = useUser();

  const renderOrigin = (origin: string) => {
    try {
      const split = origin.split(':')[0];
      return (
        <span className="align-middle break-all">
          Origin: <span className="font-light">{split}</span>
        </span>
      );
    } catch {
      return <>{origin}</>;
    }
  };

  return (
    <div className="ml-2">
      {inquiries?.map((inq: Inquiry) => (
        <div
          key={inq.ts.toString()}
          className="py-4 indent-2 border-b-2 border-neutral-200 flex flex-col gap-1 last:border-0"
        >
          <TabRow>
            <FaCalendar size="1rem" />
            {getLocaleTime(inq.ts.toString())}
          </TabRow>

          {inq?.moderator && (
            <TabRow>
              <FaUserAlt size="1rem" />
              {getModeratorEmail(inq?.moderator, moderators)}
            </TabRow>
          )}
          {inq?.origin && (
            <TabRow>
              <FaInfoCircle size="1rem" />
              <span>{renderOrigin(inq?.origin)}</span>
            </TabRow>
          )}
        </div>
      ))}
    </div>
  );
};

export { Inquiries };
