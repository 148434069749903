import { Link, useSearchParams } from 'react-router-dom';
import { Alert } from '@components/shared/Alert';

const RetrieveApiKey = () => {
  const [searchParams] = useSearchParams();
  const pidSetting = searchParams.get('pid-setting') || '';
  const platformId = searchParams.get('pid') || '';

  const id = pidSetting ? pidSetting : platformId;

  if (!id) return null;

  return (
    <Alert variant="info">
      <>Need an API key? You can create one in </>
      <Link
        className="link bg-transparent underline hover:bg-transparent px-0"
        to={`/settings/api-keys?pid=${id}&pid-setting=${platformId}`}
      >
        Platform Settings
      </Link>
      .
    </Alert>
  );
};

export { RetrieveApiKey };
