import { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { PolicyRuleTable } from '@components/Policy/PolicyRuleTable';
import { useUser } from '@context/UserProvider';
import { Dialog } from '@components/shared/Dialog';
import { AddNewRuleForm } from '@components/Policy/PolicyActions/AddNewRule';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';
import { Loader } from '@components/shared/Loader';
import { PolicyRuleType } from '@typeDef/Policy';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { RulesHistory } from '@components/Policy/PolicyActions/RulesHistory';
import { strategiesIcons } from '@components/Policy/constant';
import { Header } from '@components/shared/Header';
import { RulesConfirmation } from '@components/Policy/PolicyActions/RulesConfirmation';
import { v4 as uuid } from 'uuid';
import { FaPencilAlt } from 'react-icons/fa';
import { EditDescriptionForm } from '@components/Policy/PolicyActions/EditDescription';
import { Toast } from '@components/shared/Toast';
import { PolicyView } from '@components/Policy/PolicyView';

const PolicyItem = () => {
  const queryClient = useQueryClient();
  const { platform, user } = useUser();
  const { putPolicyRule, usePolicy, useStrategies, updatePolicyStatus } =
    useApiService();
  const { data: strategyList } = useStrategies();
  const { policyId } = useParams();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showEditDescription, setShowEditDescription] =
    useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [ruleSelected, setRuleSelected] = useState<PolicyRuleType | null>(null);
  const [rulesList, setRulesList] = useState<PolicyRuleType[] | []>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showArchive, setShowArchive] = useState(false);

  const {
    data: policy,
    error,
    isLoading
  } = usePolicy({ code: policyId || '' });

  useEffect(() => {
    if (strategyList) {
      const rules: PolicyRuleType[] = [];
      policy?.policy_rules?.forEach((x) => {
        rules.push({
          ...x,
          lower_threshold: x.lower_threshold * 100,
          upper_threshold: x.upper_threshold * 100,
          uuid: uuid(),
          strategyIcon: strategyList
            .filter((s) => s.labels?.includes(x.label || ''))
            .map((p) => strategiesIcons[p.type] || '/favicon-32x32.png')
        });
      });
      return setRulesList(rules);
    }
  }, [policy, strategyList]);

  if (isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (!policy) return <div>No policy rules found</div>;
  if (error) return <Navigate to={`/policies?pid=${platform?.id}`} />;

  const archivePolicy = (archived: boolean) => {
    updatePolicyStatus.mutate(
      { archived, code: policy?.code },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: ['Policy'] });
          queryClient.invalidateQueries({ queryKey: ['policies'] });
        },
        onSettled: async () => {
          setShowArchive(false);
        },
        onError: async () => {
          setErrorMsg('Failed to submit change, please try again later.');
        }
      }
    );
  };

  const onSubmit = () => {
    if (!rulesList) return;

    const rules = rulesList
      .filter((rule: PolicyRuleType) => rule.status !== 'deleted')
      .map((rule: PolicyRuleType) => ({
        lower_threshold: rule.lower_threshold / 100,
        upper_threshold: rule.upper_threshold / 100,
        label: rule.label,
        field: rule.field,
        strategy: rule.strategy,
        content_type: rule.content_type
      }));
    putPolicyRule.mutate(
      { rules, code: policyId || '' },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: ['Policy'] });
        },
        onSettled: async () => {
          setShowConfirmation(false);
        },
        onError: async () => {
          setErrorMsg('Failed to submit change, please try again later.');
        }
      }
    );
  };

  const getIndex = (rule: PolicyRuleType) =>
    rulesList?.findIndex(
      (x: PolicyRuleType) =>
        x.label === rule.label && x.content_type === rule.content_type
    );

  const closeDialogs = () => {
    setShowAdd(false);
    setShowEdit(false);
    setRuleSelected(null);
  };

  const updateRules = (rule?: PolicyRuleType, index?: number) => {
    if (rule && !!index?.toString()) {
      rulesList[index] = rule;
      setRulesList([...rulesList]);
    }
    closeDialogs();
  };

  const onDiscard = (rule: PolicyRuleType) => {
    const index = getIndex(rule);

    if (!rule.status || index < 0) return;

    if (rule?.status === 'added') {
      rulesList?.splice(index, 1);
      setRulesList([...rulesList]);
    }

    if (rule.status === 'edited') {
      updateRules(rule.original, index);
    }

    if (rule.status === 'deleted') {
      delete rule.status;
      updateRules(rule, index);
    }
  };

  const onEdit = (rule: PolicyRuleType) => {
    const index = getIndex(rule?.original || rule);
    const updatedRule = { ...rule, status: 'edited' };
    updateRules(updatedRule, index);
  };

  const onDelete = (rule: PolicyRuleType) => {
    const index = getIndex(rule);
    const updatedRule = { ...rule, status: 'deleted' };
    updateRules(updatedRule, index);
  };

  const onAdd = (rule: PolicyRuleType) => {
    setRulesList([...rulesList, { ...rule, status: 'added', uuid: uuid() }]);
    closeDialogs();
  };

  const ArchiveButton = ({ title }: { title: string }) => (
    <Button
      hiddenTitle={title}
      onClick={() => setShowArchive(true)}
      style="rounded-sm text-sm font-bold flex items-center h-8 px-4 buttonOutlined"
    >
      {title}
    </Button>
  );

  const isArchived = policy?.archived;

  return (
    <div className="mt-1 flex-shrink">
      <Header>
        <div className="flex flex-col">
          <div className="text-cta font-bold">Policy</div>
          <h2 className="overflow-hidden min-w-[160px] line-clamp-3">
            {policy.name}{' '}
            <span className="inline text-sm"> - {policy.code}</span>
          </h2>
        </div>
        {user?.isUserAdmin && (
          <div className="flex-row flex gap-2">
            <ArchiveButton title={isArchived ? 'Restore' : 'Archive'} />
            <Button
              hiddenTitle="Edit policy description"
              onClick={() => setShowEditDescription(!showEditDescription)}
              style="rounded-sm text-sm font-bold flex items-center h-8 px-4 buttonOutlined"
            >
              <FaPencilAlt size="14" className="mr-1" /> Edit
            </Button>
          </div>
        )}
      </Header>
      <div className="px-8">
        <PolicyView
          policy={policy}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          showRules={true}
        >
          <PolicyRuleTable
            rulesList={rulesList}
            onDiscard={onDiscard}
            onDelete={onDelete}
            onEdit={onEdit}
            show={showEdit}
            setShow={setShowEdit}
            ruleSelected={ruleSelected}
            setRuleSelected={setRuleSelected}
          />
        </PolicyView>
      </div>
      <Dialog
        show={showEditDescription}
        close={() => setShowEditDescription(false)}
      >
        <EditDescriptionForm
          policy={policy}
          onClose={() => setShowEditDescription(false)}
        />
      </Dialog>
      <Dialog show={showAdd} close={() => setShowAdd(false)}>
        <AddNewRuleForm
          onAdd={onAdd}
          strategies={strategyList}
          existingRules={rulesList}
        />
      </Dialog>
      <Dialog show={showHistory} close={() => setShowHistory(false)}>
        <RulesHistory policy={policyId} />
      </Dialog>
      <Dialog show={showArchive} close={() => setShowArchive(false)}>
        <div className="p-4 max-h-[50%]">
          <h2 className="mb-6">Are you sure?</h2>
          Archiving will {isArchived ? 'enable' : 'disable'} this policy for
          your account. You can restore at any time by clicking the{' '}
          {isArchived ? 'restore' : 'archive'} button in the policy page.
          <div className="flex gap-2 mt-6 justify-end">
            <SubmitButtons
              onClose={() => setShowArchive(false)}
              onSubmit={() => archivePolicy(!isArchived)}
              submitLabel="Confirm"
              isLoading={putPolicyRule?.isPending}
              submitStyle="font-bold py-2"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        show={showConfirmation}
        close={() => setShowConfirmation(false)}
        dialogStyle="w-1/3 bg-custom-bg"
      >
        <RulesConfirmation
          rulesList={rulesList}
          isLoading={putPolicyRule?.isPending}
          onSubmit={onSubmit}
          setShowConfirmation={setShowConfirmation}
        />
      </Dialog>
      {rulesList.filter((x: PolicyRuleType) => !!x.status).length > 0 && (
        <Button
          title="Publish changes"
          style="float-right my-8 mr-8 font-bold button py-2"
          onClick={() => setShowConfirmation(true)}
        />
      )}
      <Toast time={7000} message={errorMsg} onClose={() => setErrorMsg('')} />
    </div>
  );
};

export default PolicyItem;
