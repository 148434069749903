import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { UpdateActionRequest, Action } from '@api/types';
import { useApiService } from '@api/services';
import { TbVersionsFilled } from 'react-icons/tb';
import { FaUserCircle } from 'react-icons/fa';
import { Loader } from '@components/shared/Loader';
import { DecisionBtn } from '@components/Settings/Actions/DecisionBtn';
import { ActionForm } from '@components/Settings/Actions/Form';
import { useUser } from '@context/UserProvider';

interface UpdateActionParams extends UpdateActionRequest {
  hint: string;
  contentDecision?: string;
  authorDecision?: string;
}

const EditAction = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { action } = useParams();
  const platformId = searchParams.get('pid-setting') || '';
  const { putActionUpdate, useActions } = useApiService();
  const { data: actionsList, isLoading, error } = useActions({ platformId });
  const [isOpenComplexType, setIsOpenComplexType] = useState<boolean>(false);
  const [nameAlreadyExists, setNameAlreadyExists] = useState<boolean>(false);
  const { reloadActions } = useUser();
  const [formInput, setFormInput] = useState<UpdateActionParams>({
    name: '',
    hint: '',
    complexTypes: [],
    description: ''
  });

  useEffect(() => {
    if (actionsList?.length && action) {
      const actionSelected =
        actionsList.find((a: Action) => a.hint === action) || {};

      Object.keys(actionSelected)
        ? setFormInput(actionSelected as UpdateActionParams)
        : navigate(-1);
    }
  }, [actionsList, action]);

  const onSubmit = () => {
    for (const i in formInput) {
      if (!formInput[i as keyof UpdateActionParams]?.length)
        delete formInput[i as keyof UpdateActionParams];
    }
    const { hint, ...payload } = formInput;
    putActionUpdate.mutate(
      {
        platformId,
        hint,
        payload
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: ['settingsActions']
          });
          reloadActions();
          navigate(-1);
        },
        onError: async () => console.log('error')
      }
    );
  };

  useEffect(() => {
    // check if name already exists
    const names = [] as string[];
    actionsList?.map((x) => {
      names.push(x.name);
    });
    names.some((name) => name.toLowerCase() === formInput['name'])
      ? setNameAlreadyExists(true)
      : setNameAlreadyExists(false);
  }, [formInput]);

  if (isLoading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <ActionForm
      isOpenComplexType={isOpenComplexType}
      onSubmit={onSubmit}
      formInput={formInput}
      nameAlreadyExists={nameAlreadyExists}
      isHintDisabled={true}
      setFormInput={setFormInput}
      setIsOpenComplexType={setIsOpenComplexType}
      actionName={action}
    >
      <div>
        <div className="font-bold mb-3">Decision</div>
        <div className="flex gap-y-4 flex-wrap justify-between">
          {formInput.contentDecision && (
            <DecisionBtn
              value={formInput.contentDecision}
              description={formInput.description}
              id="contentDecision"
              icon={<TbVersionsFilled size="24" />}
              selection={formInput['contentDecision']}
              onClick={() => {}}
            />
          )}
          {formInput.authorDecision && (
            <DecisionBtn
              value={formInput.authorDecision}
              description={formInput.description}
              id="authorDecision"
              icon={<FaUserCircle size="24" />}
              selection={formInput['authorDecision']}
              onClick={() => {}}
            />
          )}
        </div>
      </div>
    </ActionForm>
  );
};

export default EditAction;
