import { useDate } from '@context/DateProvider';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { useNavigate } from 'react-router-dom';
import { Incidents } from '@components/Dashboard/Workflow/Incidents';
import { Resolutions } from '@components/Dashboard/Workflow/Resolutions';
import { SankeyChart } from '@components/Dashboard/Workflow/SankeyChart';

type RateProp = {
  val?: number;
  reference?: number;
};

const getRate = ({ val, reference }: RateProp) =>
  val && reference ? ((val / reference) * 100).toFixed(2) : 0;

const getPercentage = ({ val, reference }: RateProp) =>
  `${getRate({ val, reference })}%`;

const Workflow = () => {
  const navigate = useNavigate();
  const { startDate, endDate } = useDate();
  const { usePoliciesBreakdownMetrics } = useApiService();
  const {
    data: policiesMetrics,
    error,
    isFetching: isLoadingPolicies
  } = usePoliciesBreakdownMetrics({ startDate, endDate });

  if (isLoadingPolicies) return <Loader />;
  if (error) navigate('/error');

  return (
    <div className="flex flex-col gap-12">
      <Incidents policiesMetrics={policiesMetrics} />
      <Resolutions policiesMetrics={policiesMetrics} />
      <SankeyChart matches={policiesMetrics?.matches} />
    </div>
  );
};

export { Workflow as default, getPercentage, getRate };
