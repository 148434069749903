import { ReactElement } from 'react';
import sanitizeHtml from 'sanitize-html';

const AudioContent = ({ val }: any): ReactElement => (
  <>
    <audio controls id={val?.src} src={val?.src} preload="auto" />
    <div className="mt-6">Transcription</div>
    <div
      style={{
        flexFlow: 'row-wrap',
        height: '200px',
        overflowY: 'auto',
        textAlign: 'justify',
        marginTop: '7px'
      }}
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(val?.txt) }}
    />
  </>
);

export { AudioContent };
