import { Incident } from '@api/types';
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6';
import { TbVersionsFilled } from 'react-icons/tb';

type incidentStatus = {
  incident?: Incident;
  style?: string;
};

const IncidentStatus = ({ incident, style }: incidentStatus) => {
  return (
    <>
      <div className={`${style} mb-0.5`}>
        {incident?.status === 'closed' && (
          <FaCircleCheck size="18" color="green" />
        )}
        {incident?.status === 'open' && (
          <FaCircleExclamation size="18" color="red" />
        )}
        {!incident?.status && <TbVersionsFilled size="18" />}
      </div>
    </>
  );
};

export { IncidentStatus };
