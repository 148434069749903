import { SimilarProperty } from '@components/Review/Evaluations/SimilarProperty';
import { PotentialViolations } from '@components/Review/Evaluations/PotentialViolations';
import { getEvaluations } from '@components/Review/utils';

const Evaluations = ({
  selectedProperty,
  setSelectedProperty,
  event,
  contentCase,
  showHeader = true
}: any) => {
  const violations = contentCase?.contents?.at(-1)?.violations || [];
  const evaluations = contentCase?.contents?.at(-1)?.evaluations;

  return (
    <div>
      {showHeader && <h5>Potential Violation</h5>}
      {evaluations && (
        <PotentialViolations
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          evaluations={
            getEvaluations(
              evaluations,
              violations || [],
              contentCase.manualEvaluations
            ) ?? []
          }
        />
      )}
      <SimilarProperty selectedProperty={selectedProperty} event={event} />
    </div>
  );
};

export { Evaluations };
