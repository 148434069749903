import { DateContextType } from '@typeDef/date';
import { DateTime } from 'luxon';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

export const DateContext = createContext<DateContextType>(undefined!);

const DateProvider = ({ children }: Props) => {
  const [searchParams] = useSearchParams();
  const endParams = searchParams.get('endDate');
  const startParams = searchParams.get('startDate');

  const [endDate, setEndDate] = useState<DateTime | undefined>(
    DateTime.fromISO(endParams as any)
  );
  const [startDate, setStartDate] = useState<DateTime | undefined>(
    DateTime.fromISO(startParams as any)
  );

  const value = useMemo(
    () => ({
      startDate: startDate as any,
      setStartDate,
      endDate: endDate as any,
      setEndDate
    }),
    [startDate, endDate]
  );

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};

function useDate() {
  const context = useContext(DateContext);
  if (context === undefined) {
    throw new Error('useDate must be used within a DateProvider');
  }
  return context;
}

export { DateProvider, useDate };
