import { useState, ChangeEvent, FormEvent } from 'react';
import { SimpleType, DomainObject, IngestContentRequest } from '@api/types';
import Papa from 'papaparse';
import { Button } from '@components/shared/Buttons';
import { MdDelete } from 'react-icons/md';
import { useApiService } from '@api/services';
import { v4 as uuidv4 } from 'uuid';
import { Dialog } from '@components/shared/Dialog';
import { IoMdAdd } from 'react-icons/io';

type Field = {
  id: string;
  type: string;
  src?: string;
};

const IngestCSV = ({ domainObject }: { domainObject: DomainObject }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { postContentInBulk } = useApiService();
  const [isAdding, setIsAdding] = useState<boolean>(true);
  const [isIngestionOK, setIsIngestionOK] = useState<boolean>();

  const [fields, setFields] = useState<Field[]>([]);
  const [csvKeys, setCsvKeys] = useState<Set<string>>(new Set());
  const [csvParsed, setCsvParsed] = useState<any>();

  const handleUploadCSV = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];

        Papa.parse<string[]>(file, {
          header: true,
          complete({ data }) {
            const fieldsList = Object.keys(data[0]).map((k: string) => ({
              id: k,
              type: guessFieldType(data[0][k as any])
            }));
            setFields(fieldsList);
            setCsvParsed(data);
            setIsAdding(false);
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const addField = () => setFields([...fields, { id: '', type: '' }]);

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    index: number
  ) => {
    const data = [...fields] as any;
    const { value, name } = e.target;
    data[index][name] = value;

    if (name === 'id') {
      data[index]['type'] = guessFieldType(csvParsed[0][value]);
    }

    csvKeys.delete(value);
    setCsvKeys(new Set(csvKeys));
    setFields(data);
  };

  const removeFields = (index: number) => {
    const data = [...fields];
    const fieldRemoved = data.splice(index, 1);
    setCsvKeys(new Set([...csvKeys, fieldRemoved[0]['id']]));
    setFields(data);
  };

  const guessFieldType = (val: string, type?: string) => {
    if (type) return type;

    const isImage = new RegExp('^https?://.*/[^/]*.(jpg|jpeg|png|webp).*');
    const isVideo = new RegExp('^https?://.*/[^/]*.(mp4|mov).*');
    const isUrl = new RegExp('^https?://.');

    if (isImage.test(val)) return SimpleType.IMAGE;
    if (isVideo.test(val)) return SimpleType.VIDEO;
    if (isUrl.test(val)) return SimpleType.URI;
    return SimpleType.TEXT;
  };

  const getFields = (field: Field, row: any) => {
    if (row[field.id])
      return {
        ...field,
        id: field.id.replace(/[^0-9a-zA-Z_-]+/g, '_'),
        src: row[field.id]
      };
  };

  const getContents = (csvToJson: any) =>
    csvToJson
      .map((row: any) => ({
        id: uuidv4(),
        type: domainObject.type,
        fields: fields.map((field) => getFields(field, row)).filter((x) => x)
      }))
      .filter((field: IngestContentRequest) => !!field.fields?.length);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    postContentInBulk.mutate(
      {
        bulk: uuidv4(),
        contents: getContents(csvParsed)
      },
      {
        onSettled: async () => setShowDialog(true),
        onSuccess: async () => {
          setIsIngestionOK(true);
          setIsAdding(true);
        },
        onError: async () => setIsIngestionOK(false)
      }
    );
  };

  return (
    <>
      {isAdding && (
        <div className="p-24 w-full">
          <div className="w-full flex justify-center p-12 border border-cta rounded">
            <input type="file" accept=".csv" onChange={handleUploadCSV} />
          </div>
        </div>
      )}
      {!isAdding && (
        <div>
          <div className="w-full flex justify-end py-3">
            <IoMdAdd
              className="text-cta cursor-pointer"
              size="28"
              onClick={addField}
            />
          </div>
          <form data-testid="form" onSubmit={handleSubmit}>
            {fields?.map((field: Field, index: number) => (
              <div className="flex gap-3 mb-3 w-full" key={index}>
                <select
                  name="id"
                  onChange={(e) => handleChange(e, index)}
                  required
                  className="border border-lightgray rounded-md p-2.5 bg-custom-bg w-full"
                >
                  {field.id ? (
                    <option>{field.id}</option>
                  ) : (
                    <option value="">Select Id</option>
                  )}
                  {[...csvKeys]?.map((x) => <option key={x}>{x}</option>)}
                </select>
                <select
                  name="type"
                  onChange={(e) => handleChange(e, index)}
                  value={field.type}
                  className="border border-lightgray rounded-md p-2.5  col-span-3 bg-custom-bg"
                >
                  <option value="">Select type</option>
                  {Object.values(SimpleType).map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end">
                  <MdDelete
                    onClick={() => removeFields(index)}
                    color="red"
                    size="28"
                    className="ml-4"
                  />
                </div>
              </div>
            ))}
            <div className="w-full flex justify-end py-3 gap-2">
              <Button onClick={() => {}} style="button font-bold py-2">
                Ingest Content
              </Button>
            </div>
          </form>
        </div>
      )}
      <Dialog
        show={showDialog}
        close={() => setShowDialog(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        {isIngestionOK ? (
          <>
            <div className="font-semibold text-xl mb-4">Content submitted</div>
            <div className="text-lg">
              Your content should appear in the Explore page shortly
            </div>
          </>
        ) : (
          <>
            <div className="font-semibold text-xl mb-4">Error found</div>
            <div className="text-lg">Please try again</div>
          </>
        )}
      </Dialog>
    </>
  );
};

export { IngestCSV };
