import { Queue } from '@api/types';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';
import { useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa6';

const SampleField = ({
  pendingSamples,
  setPendingSamples,
  queues,
  sample,
  edit
}: {
  pendingSamples: Map<string, number>;
  setPendingSamples: (p: Map<string, number>) => void;
  queues: Queue[];
  sample?: any;
  edit: boolean;
}) => {
  const { key, value } = sample || {};
  const [selectedQueue, setSelectedQueue] = useState(
    queues?.find((q) => String(q.id) === key)?.name || 'Select queue'
  );

  const [threshold, setThreshold] = useState<number>(value);

  const editThreshold = (val: number) => {
    setThreshold(val);
    if (sample) {
      const list = new Map(pendingSamples);
      const queueId = String(queues?.find((q) => q.name === selectedQueue)?.id);
      list.set(queueId, threshold);
      setPendingSamples(new Map(list));
    }
  };

  const submitSample = () => {
    const list = new Map(pendingSamples);
    const queueId = String(queues?.find((q) => q.name === selectedQueue)?.id);
    list.set(queueId, threshold);
    setPendingSamples(new Map(list));
    setSelectedQueue('Select queue');
    setThreshold(0);
  };

  const removeSample = () => {
    const list = new Map(pendingSamples);
    const queueId = String(queues?.find((q) => q.name === selectedQueue)?.id);
    list.delete(queueId);
    setPendingSamples(new Map(list));
  };

  return (
    <div className="mb-2">
      <div className="flex flex-row gap-2 items-center relative">
        <Dropdown
          buttonStyle="button flex justify-center items-center h-full min-w-44"
          listStyle="absolute z-40 border bg-custom-bg border-border min-w-44 rounded-sm overflow-auto"
          itemStyle="hover:bg-transparent hover:text-cta"
          list={queues?.map((queue) => queue.name) || []}
          keepOpen={true}
          title={selectedQueue}
          onAction={setSelectedQueue}
          disabled={!edit || sample}
          arrow={edit}
        />
        <div className="flex flex-row items-center w-full gap-1">
          <div className="border-[1px] border-neutral-300 py-1 w-full rounded-md flex gap-2 h-10 items-center">
            <div className="pl-2 font-medium">Threshold:</div>
            <input
              type="number"
              className="bg-transparent w-full focus:outline-none"
              placeholder="0"
              min={0}
              step={0.001}
              max={1}
              value={threshold}
              onChange={(e) => editThreshold(parseFloat(e.target.value))}
              disabled={!edit}
            />
          </div>
          {!sample && (
            <Button
              onClick={submitSample}
              style="buttonOutlined"
              disabled={
                selectedQueue === 'Select queue' ||
                !threshold ||
                threshold > 1 ||
                !edit
              }
            >
              <FaPlus size={15} />
            </Button>
          )}
          {sample && edit && (
            <Button onClick={removeSample} style="buttonOutlined">
              <FaTrash size={15} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const ThresholdCriteria = ({
  edit,
  setEdit,
  criterion,
  submitEdit,
  keyTo,
  isNewCriteria,
  queues,
  operation
}: {
  edit: boolean;
  setEdit: (edit: boolean) => void;
  criterion: any;
  submitEdit: (operation: string, changes: any) => void;
  keyTo: string;
  isNewCriteria?: boolean;
  queues: Queue[];
  operation: string;
}) => {
  const { include } = criterion || {};
  const [pendingSamples, setPendingSamples] = useState<Map<string, number>>(
    new Map(Object.entries(include?.[keyTo] || {}))
  );
  const [unSelectedQueues, setUnSelectedQueues] = useState(
    queues.filter((queue) => !pendingSamples.has(String(queue.id)))
  );

  useEffect(() => {
    setUnSelectedQueues(
      queues.filter((queue) => !pendingSamples.has(String(queue.id)))
    );
  }, [pendingSamples]);

  const handleClose = () => {
    setEdit(false);
    setPendingSamples(new Map(Object.entries(criterion?.[keyTo] || {})));
  };

  return (
    <div className="w-full">
      {Array.from(pendingSamples)?.map(([key, value], index) => (
        <SampleField
          pendingSamples={pendingSamples}
          setPendingSamples={setPendingSamples}
          queues={queues}
          sample={{ key, value }}
          edit={edit}
          key={`${{ key, value }}-${index}`}
        />
      ))}
      {edit && (
        <SampleField
          pendingSamples={pendingSamples}
          setPendingSamples={setPendingSamples}
          queues={unSelectedQueues}
          edit={edit}
        />
      )}
      {edit && (
        <div className="w-full flex justify-end mt-3 gap-2">
          <SubmitButtons
            onClose={!isNewCriteria ? handleClose : undefined}
            onSubmit={() =>
              submitEdit(operation, {
                changes: {
                  include: Array.from(pendingSamples).reduce(
                    (acc: any, [key, value]: any) => {
                      acc[key] = value;
                      return acc;
                    },
                    {}
                  )
                },
                keyName: keyTo
              })
            }
            submitLabel={'Save'}
            isLoading={false}
          />
        </div>
      )}
    </div>
  );
};

export { ThresholdCriteria };
