import { Policy } from '@api/types';
import { KeywordChip } from '@components/Settings/Strategies/KeywordChip';
import { SubmitButtons } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';
import { useEffect, useState } from 'react';

const DropdownField = ({
  edit,
  isArrayField,
  unSelectedOptions = [],
  optionsData,
  setter,
  options,
  type,
  isObjList,
  inputData
}: {
  edit: boolean;
  isArrayField: boolean;
  unSelectedOptions?: any;
  optionsData: any;
  setter: any;
  options: any;
  type: string;
  isObjList: boolean;
  inputData: Policy[];
}) => {
  const [search, setSearch] = useState<string>('');
  const handleArg = (arg: string) => {
    if (!isArrayField) {
      setter(arg);
      setSearch('');
      return;
    }

    const option = isObjList
      ? inputData.find((option: Policy) => option?.name === arg)?.code || arg
      : arg;

    if (options) {
      options.has(option) ? options.delete(option) : options.add(option);
      setter(new Set(options));
    }

    setSearch('');
  };

  const filteredDropdownOptions = unSelectedOptions?.filter((x: string) =>
    x?.includes(search.toLowerCase())
  );
  return (
    <div className="w-full flex flex-row gap-1">
      <div className="p-1 h-10 flex items-center px-2 w-20 overflow-hidden">
        {type}:
      </div>
      <div className="border-[1px] border-neutral-300 p-1 w-full rounded-md flex gap-2 h-10 relative">
        <div className="flex flex-row gap-1 items-center">
          {!!optionsData?.length &&
            optionsData?.map(
              (option: any) =>
                option && (
                  <KeywordChip
                    keyword={option}
                    key={option}
                    style="py-0.5"
                    onClick={edit && isArrayField ? handleArg : undefined}
                  />
                )
            )}
        </div>
        <Dropdown
          buttonStyle="bg-transparent flex justify-center items-center h-full"
          listStyle="absolute z-40 border bg-custom-bg border-border w-52 rounded-sm overflow-auto"
          itemStyle="hover:bg-transparent hover:text-cta"
          list={
            filteredDropdownOptions?.length
              ? filteredDropdownOptions
              : unSelectedOptions
          }
          keepOpen={true}
          title={
            <input
              type="text"
              className="bg-transparent pl-2 w-full focus:outline-none h-full"
              placeholder={edit ? 'Search...' : ''}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              disabled={!edit}
            />
          }
          onAction={handleArg}
          arrow={false}
          disabled={!edit}
        />
      </div>
    </div>
  );
};

const DropdownCriteria = ({
  edit,
  setEdit,
  criterion,
  submitEdit,
  keyTo,
  isNewCriteria = false,
  inputData,
  singleVal,
  operation
}: {
  edit: boolean;
  setEdit: (edit: boolean) => void;
  inputData: any;
  criterion: any;
  submitEdit: (operation: string, changes: any) => void;
  keyTo: string;
  isNewCriteria?: boolean;
  singleVal?: boolean;
  operation: string;
}) => {
  const isObjList = !!inputData[0].name;
  const { include, exclude } = criterion || {};
  const isArrayField = !singleVal;
  const [includeOption, setIncludeOption] = useState<string>(include?.[keyTo]);
  const [excludeOption, setExcludeOption] = useState<string>(exclude?.[keyTo]);
  const [includeOptions, setIncludeOptions] = useState<Set<string>>(
    new Set(include?.[keyTo])
  );
  const [excludeOptions, setExcludeOptions] = useState<Set<string>>(
    new Set(exclude?.[keyTo])
  );
  const getUnselected = () => {
    const list =
      inputData?.map((option: any) => {
        if (
          !includeOptions?.has(option.code || option) &&
          !excludeOptions?.has(option.code || option)
        )
          return isObjList ? option?.name : option;
      }) || [];
    return list.filter(Boolean);
  };
  const [unSelectedOptions, setUnSelectedOptions] = useState(getUnselected());

  useEffect(() => {
    setUnSelectedOptions(getUnselected());
  }, [includeOptions, excludeOptions, criterion]);

  const optionsInclude = isArrayField
    ? Array.from(includeOptions)
    : [includeOption];
  const optionsExclude = isArrayField
    ? Array.from(excludeOptions)
    : [excludeOption];

  const handleClose = () => {
    setEdit(false);
    setIncludeOptions(new Set(include?.[keyTo]));
    setExcludeOptions(new Set(exclude?.[keyTo]));
    setIncludeOption(include?.[keyTo]);
    setExcludeOption(exclude?.[keyTo]);
  };

  const fieldProps = {
    isArrayField,
    edit,
    unSelectedOptions,
    isNewCriteria,
    isObjList,
    inputData
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        <DropdownField
          optionsData={optionsInclude}
          setter={isArrayField ? setIncludeOptions : setIncludeOption}
          options={includeOptions}
          type="Include"
          {...fieldProps}
        />
        <DropdownField
          optionsData={optionsExclude}
          setter={isArrayField ? setExcludeOptions : setExcludeOption}
          options={excludeOptions}
          type="Exclude"
          {...fieldProps}
        />
      </div>
      {edit && (
        <div className="w-full flex justify-end mt-3 gap-2">
          <SubmitButtons
            onClose={!isNewCriteria ? handleClose : undefined}
            onSubmit={() =>
              submitEdit(operation, {
                changes: {
                  include: isArrayField
                    ? Array.from(includeOptions)
                    : includeOption,
                  exclude: isArrayField
                    ? Array.from(excludeOptions)
                    : excludeOption
                },
                keyName: keyTo
              })
            }
            submitLabel="Save"
            isLoading={false}
          />
        </div>
      )}
    </div>
  );
};

export { DropdownCriteria };
