import { useEffect } from 'react';
import { ContentDecision } from '@api/types';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import { PolicySelectedList } from '@components/Review/Shared/PolicySelectedList';
import { FaArrowDown } from 'react-icons/fa';
import { Button } from '@components/shared/Buttons';
import { FiCornerDownLeft } from 'react-icons/fi';
import { Loader } from '@components/shared/Loader';

type Props = {
  evaluatedDecision: ContentDecision;
  hideModal: () => void;
  isLoading: boolean;
  onSubmit: any;
  showPolicyList: boolean;
  actionSelected: any;
  payload: any;
  onReject: () => void;
};

const ConfirmQA = ({
  evaluatedDecision,
  hideModal,
  isLoading,
  onSubmit,
  showPolicyList,
  actionSelected,
  payload,
  onReject
}: Props) => {
  const { enableScope, disableScope } = useHotkeysContext();
  const getPrevViolations = (evaluatedDecision: ContentDecision) => {
    const { fieldViolations, violations } = evaluatedDecision;
    if (fieldViolations) return fieldViolations;
    if (violations)
      return violations.map((p: string) => ({
        policy: p
      }));
  };

  useEffect(() => {
    enableScope('modal');
    disableScope('actions');
    disableScope('policies');
    disableScope('confirmAction');

    return () => {
      showPolicyList ? enableScope('policies') : enableScope('actions');
      enableScope('confirmAction');
    };
  }, []);

  useHotkeys('enter', () => onReject());
  useHotkeys('ctrl+enter', () => onSubmit());
  useHotkeys('esc', () => hideModal());

  return (
    <>
      <div className="text-2xl font-bold mb-4">
        Confirm disagreement with original decision
      </div>
      <div className="text-lg font-semibold mb-3">Previous decision</div>
      <PolicySelectedList payload={getPrevViolations(evaluatedDecision)}>
        <div className="flex">
          <div className="font-semibold mr-2">Decision:</div>
          <span>{evaluatedDecision.decision}</span>
        </div>
      </PolicySelectedList>

      <div className="relative flex py-3 items-center">
        <div className="flex-grow border-t border-neutral-400"></div>
        <FaArrowDown className="mx-3" />
        <div className="flex-grow border-t border-neutral-400"></div>
      </div>

      <div className="text-lg font-semibold mb-3">QA Assessment:</div>
      <PolicySelectedList payload={payload?.violations}>
        <div className="flex">
          <div className="font-semibold mr-2">Decision:</div>
          <span>{actionSelected?.decision}</span>
        </div>
      </PolicySelectedList>
      {!isLoading ? (
        <div className="flex justify-end gap-2 mt-4">
          <Button
            style="button bg-cta-disabled flex gap-2 py-2 font-bold text-neutral-900"
            hiddenTitle="QA Agreed"
            onClick={onSubmit}
          >
            <kbd className="py-0.5">ctrl</kbd> +
            <kbd className="py-0.5">
              <FiCornerDownLeft />
            </kbd>{' '}
            No
          </Button>
          <Button
            style="button py-2"
            hiddenTitle="QA rejected"
            onClick={onReject}
          >
            <kbd className="py-0.5">
              <FiCornerDownLeft />
            </kbd>{' '}
            Yes
          </Button>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export { ConfirmQA };
