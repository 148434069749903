type Props = {
  htmlFor: string;
  label: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  labelStyle?: string;
  [key: string]: any;
};

const Input = ({
  htmlFor,
  label,
  type,
  required = false,
  placeholder,
  labelStyle = 'text-sm',
  ...rest
}: Props) => {
  return (
    <div className="flex flex-col w-full">
      <label htmlFor={htmlFor} className={`${labelStyle}`}>
        {label}
      </label>
      <input
        role="input"
        type={type}
        data-testid={label}
        id={htmlFor}
        className="mt-2 p-2.5 border-2 border-border rounded-md outline-none bg-custom-bg disabled:bg-bg-3"
        required={required}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export { Input };
