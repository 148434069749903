import { Button } from '@components/shared/Buttons';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate } from 'react-router-dom';

interface errorProps {
  status?: number | string;
  description?: string;
  title?: string;
}

export default function ErrorFallback() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    status = 404,
    description = 'We are having some problems reaching the page you requested, please visit our home page',
    title = 'Page not found'
  } = (location.state as errorProps) || {};

  const getGraphic = () => {
    switch (status) {
      case 500:
        return '/assets/images/glitch-500.svg';
      case 'nocase':
        return '/assets/images/glitch-q.svg';
      default:
        return '/assets/images/glitch-404.svg';
    }
  };

  useHotkeys('enter', () => navigate('/queues'));

  return (
    <div className="flex flex-col w-full justify-center items-center h-full gap-3">
      <img src={getGraphic()} alt="error" className="max-w-full h-1/2" />
      <h1>{title}</h1>
      <h6 className="max-w-[600px] text-center">{description}</h6>
      <Button
        onClick={() => navigate('/queues')}
        title="Back home"
        style="button py-3 px-5 mt-3"
      ></Button>
    </div>
  );
}
