import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { Link } from '@components/shared/Link';
import { Button } from '@components/shared/Buttons';

const Platform = ({
  platform,
  platformId,
  itemSelected,
  platformSection,
  user
}: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const pIdSetting = searchParams.get('pid-setting') || '';

  useEffect(() => {
    platform?.id.toString() === pIdSetting ? setOpen(true) : setOpen(false);
  }, [itemSelected, pIdSetting]);

  if (!platform) return null;

  return (
    <div className="py-2">
      <Button
        style={`flex items-center w-full justify-between px-4 py-2 hover:bg-transparent
          ${platformSection == platform?.id.toString() && 'text-cta font-bold'}
        `}
        onClick={() => setOpen(!open)}
        hiddenTitle={`Selected ${platform.name}`}
      >
        {platform.name}
        {open && <AiOutlineArrowUp />}
        {!open && <AiOutlineArrowDown />}
      </Button>
      {open && (
        <div className="flex flex-col">
          <Link
            to={`general?pid=${platformId}&pid-setting=${platform.id}`}
            title="General"
          />
          <Link
            to={`actions?pid=${platformId}&pid-setting=${platform.id}`}
            title="Actions"
          />
          {!!user?.isUserAdmin && (
            <>
              <Link
                to={`queues?pid=${platformId}&pid-setting=${platform.id}`}
                title="Queues"
              />
              <Link
                to={`api-keys?pid=${platformId}&pid-setting=${platform.id}`}
                title="API Keys"
              />
              <Link
                to={`webhooks?pid=${platformId}&pid-setting=${platform.id}`}
                title="Webhook Integration"
              />
              <Link
                to={`sqs?pid=${platformId}&pid-setting=${platform.id}`}
                title="SQS Integration"
              />
              <Link
                to={`regulations?pid=${platformId}&pid-setting=${platform.id}`}
                title="Regulations"
              />
              <Link
                to={`transparency?pid=${platformId}&pid-setting=${platform.id}`}
                title="Transparency Report"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

const Platforms = ({ itemSelected, setItemSelected }: any) => {
  const { user, platforms } = useUser();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid') || '';
  const platformSection = searchParams.get('pid-setting') || '';

  if (!user) return null;

  return (
    <>
      <h4 className="px-4 h-24 flex items-center text-cta">Platforms</h4>
      <div className="mb-2.5  p-0">
        {platforms?.map((platform) => (
          <Platform
            key={platform.id}
            platform={platform}
            user={user}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            platformId={platformId}
            platformSection={platformSection}
          />
        ))}
      </div>
    </>
  );
};

export default Platforms;
