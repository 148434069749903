import { useState } from 'react';
import { Loader } from '@components/shared/Loader';
import { FiLink } from 'react-icons/fi';
import { ContentFilterControl } from '@components/shared/ContentFilterControl';
import { useApiService } from '@api/services';
import { UserInfo } from '@components/Review/UserInfo/index';
import { ContentCaseShort } from '@api/types';
import { ModalInquiry } from '@components/shared/ModalInquiry';
import { CellWithCopy } from '@components/shared/Table/CellWithCopy';
import { EventTimeline } from '@components/Review/Shared/EventTimeline';
import { Button } from '@components/shared/Buttons';
import { Metadata } from '@components/Review/UserInfo/Metadata';
import { isNotAlreadyInquiried } from '@components/shared/EventCard/helper';

const ViewEvent = ({
  contentCase,
  setShow
}: {
  contentCase: ContentCaseShort;
  setShow?: (val: boolean) => void;
}) => {
  const { useContentEvent, useAuthorHistory } = useApiService();
  const [isInquiryModalVisible, setIsInquiryModalVisible] = useState(false);

  const {
    data: contentCaseApi,
    isLoading: isLoadingCs,
    error: contentCaseApiError
  } = useContentEvent({
    contentType: contentCase.ct,
    contentId: contentCase.cid
  });

  const { data: authorHistory, isLoading: isAuthorHistoryLoading } =
    useAuthorHistory({
      authorId: contentCaseApi?.contents?.at(-1)?.author ?? ''
    });

  if (isLoadingCs || isAuthorHistoryLoading) {
    return <Loader />;
  }

  if (contentCaseApiError) return <div>error</div>;

  if (!contentCaseApi) return <div>No Event found</div>;

  return (
    <>
      <div className="mb-3 items-center flex justify-between pt-3">
        <div className="hidden xl:flex flex-row">
          <span className="mr-2 font-semibold">Id:</span>{' '}
          <CellWithCopy val={contentCaseApi?.cid} width="w-20" />
        </div>
        <div className="hidden lg:block">
          <ContentFilterControl />
        </div>
        {isNotAlreadyInquiried(contentCaseApi) && (
          <Button
            style="button text-lg"
            onClick={() => setIsInquiryModalVisible(true)}
            hiddenTitle="Inquiry single event"
          >
            Open incident
          </Button>
        )}
      </div>
      <hr />
      <div className="displayCenter mt-3">
        <div className="sm:block md:flex md:gap-6 w-full max-w-[1750px]">
          <div className="hidden 2xl:block 2xl:w-[280px] cursor-default">
            <UserInfo
              majorStrikes={authorHistory?.major_strikes}
              minorStrikes={authorHistory?.minor_strikes}
              incidents={authorHistory?.incidents}
              cases={authorHistory?.cases}
              strikes={authorHistory?.strikes}
              userId={contentCaseApi?.contents?.at(-1)?.author}
              setShow={setShow}
            />
            <Metadata metadata={contentCaseApi.contents?.at(-1)?.metadata} />
          </div>
          <div className="grow">
            <div className="mb-2.5 flexRow h-7 justify-between">
              <h4>Content Timeline</h4>
              <Button
                style="button ml-2 flex items-center space-between"
                onClick={() =>
                  window.open(contentCaseApi?.contents?.at(-1)?.src)
                }
                hiddenTitle="View Event Live"
              >
                <FiLink />{' '}
                <span className="hidden xl:block ml-2">View Live</span>
              </Button>
            </div>
            <EventTimeline contentCase={contentCaseApi} />
          </div>
          <ModalInquiry
            isInquiryModalVisible={isInquiryModalVisible}
            toggleInquiryModal={() => setIsInquiryModalVisible(false)}
            selectedCS={new Map().set(contentCaseApi?.cid, contentCaseApi)}
          />
        </div>
      </div>
    </>
  );
};

export { ViewEvent };
