import { UserHistoryCard } from './UserHistoryCard';
import { Button } from '@components/shared/Buttons';
import { FiCopy } from 'react-icons/fi';

type UserInfoProps = {
  userId?: string;
  majorStrikes?: number;
  minorStrikes?: number;
  incidents?: number;
  cases?: number;
  strikes?: number;
  setShow?: (val: boolean) => void;
  showHeader?: boolean;
};

const UserInfo = ({
  userId = '',
  majorStrikes = 0,
  minorStrikes = 0,
  incidents = 0,
  cases = 0,
  strikes = 0,
  setShow,
  showHeader = true
}: UserInfoProps) => {
  return (
    <div>
      {showHeader && (
        <div className="flex justify-between mb-4 items-center">
          <h4>User History</h4>
          <Button
            style="iconBtnOutlined h-7 w-7"
            tooltip="Copy user ID"
            onClick={() => navigator.clipboard.writeText(userId)}
          >
            <FiCopy size="18" />
          </Button>
        </div>
      )}
      <div className="flex flex-col min-w-[160px] ">
        <div className="flex flex-col pb-4">
          <UserHistoryCard
            url={`/explore?q=${encodeURIComponent(userId)}&resolutions=enforced&update=true`}
            title="Strikes"
            total={strikes}
            setShow={setShow}
          >
            <div className="flex flex-row gap-2 ml-[5px] mt-0.5">
              <div className="flex flex-row gap-2 justify-between items-baseline">
                <div className="text-xs font-semibold">{majorStrikes}</div>
                <div className="text-xs font-normal">Major</div>
              </div>
              <div className="flex flex-row gap-2 justify-between items-baseline">
                <div className="text-xs font-semibold">{minorStrikes}</div>
                <div className="text-xs font-normal">Minor</div>
              </div>
            </div>
          </UserHistoryCard>

          <UserHistoryCard
            url={`/explore?q=${encodeURIComponent(userId)}&status=incident&update=true`}
            title="Incidents"
            total={incidents}
            setShow={setShow}
          />
          <UserHistoryCard
            url={`/explore?q=${encodeURIComponent(userId)}&update=true`}
            title="Cases"
            total={cases}
            setShow={setShow}
          />
        </div>
        <div className="border-b border-border hidden 2xl:block" />
      </div>
    </div>
  );
};

export { UserInfo };
