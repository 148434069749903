import { useEffect } from 'react';
import { SubmitButtons } from '@components/shared/Buttons';
import { useForm } from 'react-hook-form';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';

type Policy = {
  code: string;
  name: string;
  description: string;
  guidelines?: string;
};

type Props = {
  policy: Policy;
  onClose: () => void;
};

const EditDescriptionForm = ({ policy, onClose }: Props) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: policy
  });
  const { putPolicyDescription } = useApiService();

  useEffect(() => {
    reset(policy);
  }, [policy]);

  const updatePolicyDescription = (data: Policy) => {
    putPolicyDescription.mutate(
      {
        code: data.code,
        policy: {
          name: data.name,
          description: data.description,
          ...(data.guidelines?.trim() ? { guidelines: data.guidelines } : {})
        }
      },
      {
        onSuccess: async () =>
          Promise.all([
            queryClient.invalidateQueries({ queryKey: ['policies'] }),
            queryClient.invalidateQueries({ queryKey: ['Policy'] })
          ]).then(() => onClose()),
        onError: (err) => {
          console.log('error', err);
        }
      }
    );
  };

  return (
    <div className="p-4 max-h-[50%]">
      <h2>Edit policy</h2>
      <form onSubmit={handleSubmit(updatePolicyDescription)}>
        <h6 className="mt-3 mb-1">Policy title</h6>
        <input
          className="w-full mb-3 roundedContainer focus:outline-none"
          {...register('name', { required: true })}
          minLength={1}
          maxLength={90}
        ></input>
        <h6 className="my-1">Description</h6>
        <textarea
          className="flex-grow resize-none focus:outline-none bg-custom-bg roundedContainer w-full mb-3 h-32 overflow-auto"
          {...register('description', { required: true })}
        />
        <h6 className="my-1">Internal guidelines</h6>
        <textarea
          className="flex-grow resize-none focus:outline-none bg-custom-bg roundedContainer w-full h-16 overflow-auto"
          {...register('guidelines', { required: false })}
        />
        <div className="flex gap-2 mt-6 justify-end">
          <SubmitButtons
            onClose={onClose}
            onSubmit={() => handleSubmit(updatePolicyDescription)}
            submitLabel="Confirm"
            disabled={!formState.isValid}
            isLoading={putPolicyDescription?.isPending}
            submitStyle="font-bold py-2"
          />
        </div>
      </form>
    </div>
  );
};

export { EditDescriptionForm };
