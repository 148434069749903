import { ChangeEvent } from 'react';
import { FaCheck } from 'react-icons/fa6';

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value?: string; // value passed to the handler
  title?: string; // label text
  checkStyle?: string;
};

const Checkbox = ({ onChange, checked, value, title, checkStyle }: Props) => {
  return (
    <label className="p-1 cursor-pointer flex">
      <input
        data-testid="inputCheckbox"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value={value}
        id={title}
        className="appearance-none"
      />
      <div className="hover:text-primary-300 flex items-center gap-2.5">
        {checked ? (
          <div
            className={`border border-cta w-5 h-5 rounded-[4px] bg-cta flex p-[2px] hover:bg-primary-300 ${checkStyle}`}
          >
            <FaCheck color="white" />
          </div>
        ) : (
          <div
            className={`bg-bg-3 border border-cta w-5 h-5 rounded-[4px] hover:bg-primary-300 ${checkStyle}`}
          />
        )}
        {title}
      </div>
    </label>
  );
};

export { Checkbox };
