export const DATE_PICKER_FORMAT = 'dd/MM/yyyy';

export const PRIORITY_COLORS = {
  Low: '#34c38f',
  Medium: '#f1b44c',
  High: '#f46a6a',
  Default: '#a5abb1'
};

export const VIDEO_PLAYER_CONFIG = {
  file: {
    attributes: {
      preload: 'metadata'
    }
  }
};

export const CSAM_INCIDENT_TYPE = {
  'Child Pornography (possession, manufacture, and distribution)':
    'Child pornography is defined as a visual depiction of a minor under the age of 18 engaged in sexually explicit conduct (18 U.S.C. 2256). It is a federal crime to possess, manufacture and/or distribute child pornography (18 U.S.C. 2251 et seq.).',
  'Child Sex Trafficking':
    'It is a federal crime to knowingly recruit, entice, harbor, transport, provide or obtain by any means a child under the age of 18 to engage in a commercial sex act. It is also illegal to benefit, either financially or by receiving anything of value, from participation in a child prostitution venture (18 U.S.C. 1591).',
  'Child Sex Tourism':
    'It is a federal crime for a U.S. citizen to travel abroad intending to engage in sexual activity with a child younger than 18 that would be illegal if it occurred in the U.S. (18 U.S.C. 2423). Individuals who commit these crimes are subject to prosecution in the U.S. even if the crime was committed on foreign soil.',
  'Child Sexual Molestation':
    'Child sexual molestation can be defined as an allegation of contact of a sexual nature between a person and a child.',
  'Misleading Domain Name':
    'It is a federal crime to knowingly use a misleading domain name on the Internet with the intent to deceive a minor into viewing material that is harmful to minors (18 U.S.C. 2252B(b)).',
  'Misleading Words or Digital Images on the Internet':
    'It is a federal crime to knowingly embed words or digital images into the source code of a website with the intent to deceive a minor into viewing material that is harmful to minors (18 U.S.C. 2252C).',
  'Online Enticement of Children for Sexual Acts':
    'It is a federal crime to use the Internet to knowingly persuade, induce, entice, or coerce a child under the age of 18 to meet for sexual acts, or to attempt to arrange such a meeting (18 U.S.C. 2422(b)).',
  'Unsolicited Obscene Material Sent to a Child':
    'It is a federal crime to send obscene material to a child under the age of 16 if the sender knows that the recipient is under the age of 16 (18 U.S.C. 1470).'
} as any;

export const IndustryClassification = {
  title: 'Industry Classification',
  description:
    'The following categorization system was created by various ESPs in January 2014. If used, populate with ESP-designated categorization scale (A1, A2, B1 or B2). ',
  tableA: {
    header: [' ', 'Content Ranking', '1', '2'],
    body: {
      rowA: ['A', 'Prepubescent Minor', 'A1', 'A2'],
      rowB: ['B', 'Pubescent Minor', 'B1', 'B2']
    }
  },
  tableB: {
    header: ['Rank', 'Term', 'Definition'],
    body: {
      rowA: [
        '1',
        'Sex Act',
        'Any image of sexually explicit conduct (actual or simulated sexual intercourse including genital-genital, oral-genital, anal-genital, or oral-anal whether between person of the same or opposite sex), bestiality, masturbation, sadistic or masochistic abuse, degradation, or any such depiction that lacks serious literary, artistic, political, or scientific value. '
      ],
      rowB: [
        '2',
        'Lascivious Exhibition',
        'Any image depicting nudity and one or more of: restraint, sexually suggestive poses, focus on genitals, inappropriate touching, adult arousal, spreading of limbs or genitals, and such depiction lacks serious literary, artistic, political, or scientific value.'
      ]
    }
  }
};
export const LOWER_THRESHOLD =
  'Anything equal or greater than the Queue Content Threshold and below the Automatic Enforcement Threshold will be sent for moderator review';
export const UPPER_THRESHOLD =
  'Anything greater than the Automatic Enforcement Threshold will be automatically actioned';

export const rootCausesList = [
  { code: 'gap', name: 'Knowledge Gap' },
  { code: 'lang', name: 'Language Barrier' },
  { code: 'tool', name: 'Tool Issue' },
  { code: 'poor', name: 'Poor Quality' },
  { code: 'ctx', name: 'Unclear Context' },
  { code: 'gray', name: 'Policy Gray Area' },
  { code: 'tribal', name: 'Tribal Knowledge' },
  { code: 'other', name: 'Others' }
];

export const DecisionTypes = [
  {
    type: 'dismiss',
    target: 'content',
    description: 'Decision indicates no violation of policies'
  },
  {
    type: 'act',
    target: 'content',
    description: 'Decision indicates a violation of policy or policies'
  },
  {
    type: 'escalate',
    target: 'content',
    description:
      'Decision moves the case to a higher level for additional review'
  },
  {
    type: 'overturn',
    target: 'content',
    description:
      'Decision overturns a previous decision that the content violated a policy'
  },
  {
    type: 'uphold',
    target: 'content',
    description:
      'Decision confirms a previous decision on violating policy/policies'
  },
  {
    type: 'act',
    target: 'author',
    description:
      'Decision that an author violated policy and all associated content is violating'
  },
  {
    type: 'escalate',
    target: 'author',
    description:
      'Decision moves the author case to a higher level for additional review'
  },
  {
    type: 'overturn',
    target: 'author',
    description:
      'Decision reverses a previous moderation decision that an author violated policies'
  },
  {
    type: 'hold',
    target: 'author',
    description:
      'Decision that an author violated policy but does not take content decisions'
  }
];
