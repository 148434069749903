import { ReactNode } from 'react';

type DecisionBtnType = {
  onClick: (target: any) => void;
  value: string;
  id: string;
  description?: string;
  icon: ReactNode;
  selection?: string;
};

const DecisionBtn = ({
  onClick,
  value,
  id,
  description,
  icon,
  selection
}: DecisionBtnType) => (
  <button
    className={`${value === selection && 'border-cta border-2 text-cta bg-highlight'}
        border-2 border-border flex flex-col gap-3 p-4 w-full lg:w-[49%] xl:w-[32%] rounded-md`}
    data-testid={value}
    onClick={() =>
      onClick({
        target: { value, id }
      })
    }
  >
    <div className="w-full">
      <div className="flex justify-between w-full">
        <h6 className="font-bold capitalize">{value}</h6>
        {icon}
      </div>
      <div className="text-fg-2 text-left mt-3">{description}</div>
    </div>
  </button>
);

export { DecisionBtn };
