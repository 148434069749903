import { NavLink, useSearchParams } from 'react-router-dom';

const Link = ({ to, title }: { to: string; title: string }) => {
  const [currentParams] = useSearchParams();

  const currentPid = currentParams.get('pid-setting');
  const destinationPid = new URLSearchParams(to).get('pid-setting');

  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <div
          className={`${isActive && currentPid === destinationPid ? 'border-cta-hover text-cta font-bold' : 'border-custom-bg'}
border-l-8 indent-2.5 px-2 py-3 text-start hover:bg-transparent hover:text-cta`}
        >
          {title}
        </div>
      )}
    </NavLink>
  );
};

export { Link };
