import { useContent } from '@context/ContentProvider';
import { SliderType } from '@typeDef/Shared';
import { useUser } from '@context/UserProvider';

const Slider = ({ name, value, onChange }: SliderType) => (
  <div className="flex">
    <label htmlFor={name}>{name}</label>
    <input
      type="range"
      id={name}
      name={name}
      min="0"
      max="1"
      step="0.05"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

const ContentFilterControl = () => {
  const { contentBlur, setContentBlur, contentGrayscale, setContentGrayscale } =
    useContent();

  const { user } = useUser();
  if (user?.org === 'trustpilot') return null;

  return (
    <div
      className="flexSpaceBetween flex-wrap justify-end"
      data-testid="contentFilterControl"
    >
      <Slider name="Blur:" value={contentBlur} onChange={setContentBlur} />
      <Slider
        name="Gray:"
        value={contentGrayscale}
        onChange={setContentGrayscale}
      />
    </div>
  );
};

export { ContentFilterControl };
