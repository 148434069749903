import { ContentCase } from '@api/types';

const isNotAlreadyInquiried = (content: ContentCase) => {
  const incident = content?.incidents?.at(-1);
  const status = incident?.status;
  const causes = incident?.causes?.at(-1);
  const isInquired = status === 'open' && causes === 'inquiry';
  return !isInquired;
};

export { isNotAlreadyInquiried };
