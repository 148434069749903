import { Fragment, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateActionRequest } from '@api/types';
import { useApiService } from '@api/services';
import { TbVersionsFilled } from 'react-icons/tb';
import { FaUserCircle } from 'react-icons/fa';
import { Loader } from '@components/shared/Loader';
import { DecisionBtn } from '@components/Settings/Actions/DecisionBtn';
import { ActionForm } from '@components/Settings/Actions/Form';
import { useUser } from '@context/UserProvider';
import { DecisionTypes } from '@src/constants';

interface CreateActionParams extends CreateActionRequest {
  [key: string]: any;
}

const CreateAction = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid-setting') || '';
  const { postNewAction, useActions } = useApiService();
  const { data: actionsList, isLoading, error } = useActions({ platformId });
  const [isOpenComplexType, setIsOpenComplexType] = useState<boolean>(false);
  const [hintAlreadyExists, setHintAlreadyExists] = useState<boolean>(false);
  const [nameAlreadyExists, setNameAlreadyExists] = useState<boolean>(false);
  const { reloadActions } = useUser();
  const [formInput, setFormInput] = useState<CreateActionParams>({
    name: '',
    hint: '',
    complexTypes: [],
    description: ''
  });

  const onSubmit = () => {
    for (const i in formInput) {
      if (!formInput[i]?.length) delete formInput[i];
    }
    postNewAction.mutate(formInput, {
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: ['settingsActions']
        });
        reloadActions();
        navigate(-1);
      },
      onError: async () => console.log('error')
    });
  };

  const onSelectDecision = (e: any) => {
    const { id, value } = e.target;
    delete formInput['contentDecision'];
    delete formInput['authorDecision'];
    setFormInput({ ...formInput, [id]: value });
  };

  useEffect(() => {
    // check if either hint or name already exists
    const hints = [] as string[];
    const names = [] as string[];
    actionsList?.map((x) => {
      hints.push(x.hint);
      names.push(x.name);
    });
    hints.some((hint) => hint.toLowerCase() === formInput['hint'])
      ? setHintAlreadyExists(true)
      : setHintAlreadyExists(false);
    names.some((name) => name.toLowerCase() === formInput['name'])
      ? setNameAlreadyExists(true)
      : setNameAlreadyExists(false);
  }, [formInput]);

  if (isLoading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <ActionForm
      isOpenComplexType={isOpenComplexType}
      onSubmit={onSubmit}
      formInput={formInput}
      nameAlreadyExists={nameAlreadyExists}
      hintAlreadyExists={hintAlreadyExists}
      setFormInput={setFormInput}
      setIsOpenComplexType={setIsOpenComplexType}
      actionName="New action"
    >
      <div>
        <div className="font-bold mb-3">Decision</div>
        <div className="flex gap-y-4 flex-wrap justify-between">
          {DecisionTypes?.map((action: any) => (
            <Fragment key={action.hint}>
              {action.target === 'content' && (
                <DecisionBtn
                  onClick={onSelectDecision}
                  value={action.type}
                  description={action.description}
                  id="contentDecision"
                  icon={<TbVersionsFilled size="24" />}
                  selection={formInput['contentDecision']}
                />
              )}
              {action.target === 'author' && (
                <DecisionBtn
                  onClick={onSelectDecision}
                  value={action.type}
                  description={action.description}
                  id="authorDecision"
                  icon={<FaUserCircle size="20" />}
                  selection={formInput['authorDecision']}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </ActionForm>
  );
};

export default CreateAction;
