import { Appeal } from '@api/types';
import { FaCalendar } from 'react-icons/fa';
import { getLocaleTime } from '@utils/date';
import { MdMessage } from 'react-icons/md';
import { TabRow } from '@components/Review/Content/HistoryTabs/TabRow';

const Appeals = ({ appeals }: { appeals?: Array<Appeal> }) => {
  if (!appeals?.length)
    return <div className="ml-1 mt-1">No appeals to show</div>;

  return (
    <div className="ml-2">
      {appeals?.map((appeal: Appeal) => (
        <div
          key={appeal.ts.toString()}
          className="py-4 indent-2 border-b-2 border-neutral-200 flex flex-col gap-1 last:border-0"
        >
          <TabRow>
            <FaCalendar size="1rem" /> {getLocaleTime(appeal.ts.toString())}
          </TabRow>

          {appeal?.statement && (
            <TabRow>
              <MdMessage className="inline break-words" size="1rem" />
              <span className="break-words">{appeal?.statement}</span>
            </TabRow>
          )}
        </div>
      ))}
    </div>
  );
};

export { Appeals };
