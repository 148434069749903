const strategiesIcons: any = {
  'Strategy Type': '/favicon-32x32.png',
  'text-sagemaker': '/checkstepText.svg',
  'image-sagemaker': '/awsImage.png',
  'image-rekognition': '/awsImage.png',
  'uri-webrisk': '/favicon-32x32.png',
  'image-csam': '/arachnidImage.svg',
  'image-unitary': '/unitaryImage.svg',
  'image-unitary-characteristics': '/unitaryImage.svg',
  'text-logically': '/logicallyText.svg',
  'text-comprehend-pii': '/awsText.png',
  'image-rekognition-ocr': '/awsImage.png',
  'video-unitary': '/unitaryVideo.svg',
  'video-rekognition': '/awsVideo.png',
  'text-hugging-face': '/checkstepText.svg',
  'image-comprehend-pii': '/awsImage.png',
  'image-hash-match': '/checkstepImage.svg',
  'text-fact-check': '/googleText.svg',
  'text-pii-spacy': '/checkstepText.svg',
  'image-qr-decoder': '/qrCodeImage.svg',
  external: '/favicon-32x32.png',
  'text-keyword': '/checkstepText.svg',
  'text-wildcard': '/checkstepText.svg',
  'text-chat-gpt-classifier': '/openAIText.svg',
  'text-finetuned-gpt-classifier': '/openAIText.svg',
  'image-face-detection': '/awsImage.png',
  'image-sightengine': '/sightengineImage.svg',
  'image-recognize-celebrities': '/awsImage.png',
  'image-rekognition-labels': '/awsImage.png',
  'text-detoxify-unitary': '/unitaryText.svg',
  'multi-chat-gpt-classifier': '/openAI.svg'
};

export { strategiesIcons };
