import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: Props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  if (!user?.isUserAdmin) {
    navigate('/queues');
    return;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
