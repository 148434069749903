import { useState, useEffect, useRef } from 'react';
import { useApiService } from '@api/services';
import { Pagination } from '@components/shared/Pagination';
import { Loader } from '@components/shared/Loader';
import { useSearchParams } from 'react-router-dom';
import { ContentFilterControl } from '@components/shared/ContentFilterControl';
import { EventCard } from '@components/shared/EventCard';
import { ModalInquiry } from '@components/shared/ModalInquiry';
import { useAnalytics } from '@context/AnalyticsProvider';
import { Button } from '@components/shared/Buttons';
import { SearchFilter } from './SearchFilter';
import { HotkeysProvider } from 'react-hotkeys-hook';
import {
  SearchResultResponse,
  SearchResultCountsResponse,
  ContentCase
} from '@api/types';
import { defaultFilters, getSearchPayload } from '@components/Explore/helper';
import { useUser } from '@context/UserProvider';
import { DatePicker } from '@components/Dashboard/DatePicker';
import { useDate } from '@context/DateProvider';

const MAX_CONTENTCASES_ALLOWED = 9500;

const Explore = () => {
  const { sendEvent } = useAnalytics();
  const { useSearchDocument, useSearchTimeCounts, useStrategies } =
    useApiService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCS, setSelectedCS] = useState<Map<string, ContentCase>>(
    new Map()
  );
  const [isInquiryModalVisible, setIsInquiryModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState<SearchResultResponse>();
  const [chartData, setChartData] = useState<SearchResultCountsResponse>();
  const [isUpdatingEvent, setIsUpdatingEvent] = useState<boolean>(false);
  const [isSearchQuery, setIsSearchQuery] = useState<boolean>(false);
  const { platform } = useUser();
  const { endDate, startDate } = useDate();
  const { data: strategyList } = useStrategies();

  const scrollRef = useRef<HTMLInputElement>(null);

  const [internalState, setInternalState] = useState<any>(new Map());
  useEffect(() => {
    scrollRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, [internalState.get('page')]);

  // Init reads url to persist and share sessions
  useEffect(() => {
    const state = new Map();
    searchParams.forEach((value, key) => {
      if (defaultFilters.includes(key)) {
        return state.has(key)
          ? state.get(key).add(value)
          : state.set(key, new Set([value]));
      }
      if (value) {
        state.set(key, value);
      }
      if (key === 'startDate') {
        state.set('startDate', startDate?.toISODate());
      }
      if (key === 'endDate') {
        state.set('endDate', endDate?.toISODate());
      }
    });
    if (!state.has('size')) {
      state.set('size', 20);
      state.set('start', 0);
    }

    setInternalState(new Map(state));
  }, [searchParams.get('startDate'), searchParams.get('endDate')]);

  // API trigger if state changes
  useEffect(() => {
    if (internalState.size > 0) {
      fetchDocuments();
    }
  }, [internalState, platform?.id]);

  const setState = (updatedState: any) => {
    if (updatedState.get('page') === internalState.get('page')) {
      updatedState.set('page', 0);
    }
    setInternalState(new Map(updatedState));
  };
  const setPaginationState = (val: number | string, key: string) => {
    const state = new Map(internalState);
    state.set(key, val);
    setState(new Map(state));
  };

  const updateParams = () => {
    const params: any = {};
    for (const [key, value] of internalState) {
      if (value) {
        params[key] =
          typeof value === 'string' || typeof value === 'number'
            ? value
            : [...value];
      }
    }
    setSearchParams(params);
  };

  const fetchDocuments = () => {
    setIsUpdatingEvent(true);
    const payload = getSearchPayload(internalState);
    setSelectedCS(new Map());

    useSearchDocument.mutate(payload, {
      onSuccess: async (data) => {
        internalState.get('q') &&
          sendEvent('user search', { query: internalState.get('q') });
        setDocumentList(data);
        updateParams();
      }
    });

    useSearchTimeCounts.mutate(payload, {
      onSuccess: async (data) => {
        setChartData(data);
      }
    });
    setIsUpdatingEvent(false);
  };

  const count =
    chartData?.contentCases?.count &&
    chartData?.contentCases?.count > MAX_CONTENTCASES_ALLOWED
      ? MAX_CONTENTCASES_ALLOWED
      : chartData?.contentCases?.count;

  return (
    <HotkeysProvider>
      <div className="flex justify-center w-full mt-3 px-8">
        <div className="w-full">
          <div className="flex flex-col items-center relative">
            {useSearchDocument.isPending && (
              <div className="absolute top-20 mr-9">
                <Loader />
              </div>
            )}
            <div className="w-full">
              <div
                className="flex-col flex py-6 rounded-md items-center"
                ref={scrollRef}
              >
                <div className="w-full flex gap-3 items-center flex-row mb-3 h-11">
                  <SearchFilter
                    internalState={internalState}
                    setInternalState={setState}
                    searchDocument={fetchDocuments}
                    inputQuery={internalState?.get('q') || ''}
                    strategyList={strategyList}
                  />
                  <div className="min-w-max h-full min-h-11">
                    <DatePicker />
                  </div>
                  <div className="max-h-[44px] empty:hidden">
                    {internalState?.get('q')?.length > 2 &&
                      !!count &&
                      selectedCS.size === 0 && (
                        <Button
                          style="button w-max h-11"
                          onClick={() => {
                            setIsSearchQuery(true);
                            setIsInquiryModalVisible(true);
                          }}
                          hiddenTitle="Open search inquiry modal"
                        >
                          Open incidents (search)
                        </Button>
                      )}
                    {selectedCS.size > 0 && (
                      <Button
                        style="button h-11 w-max"
                        onClick={() => {
                          setIsSearchQuery(false);
                          setIsInquiryModalVisible(true);
                        }}
                        hiddenTitle="Bulk Inquiry"
                      >
                        <>
                          Open incidents{' '}
                          {!![...selectedCS].length && (
                            <span>({selectedCS.size})</span>
                          )}
                        </>
                      </Button>
                    )}
                  </div>
                </div>
                <div className="flex w-full justify-between items-center mt-3">
                  <h5>
                    Total events{' '}
                    {count === MAX_CONTENTCASES_ALLOWED
                      ? `${MAX_CONTENTCASES_ALLOWED}+`
                      : count}
                  </h5>
                  <div className="hidden mlg:block">
                    <ContentFilterControl />
                  </div>
                </div>
              </div>
              {isUpdatingEvent ? (
                <Loader />
              ) : (
                <div className="w-full flex flex-wrap justify-between gap-3 rounded-md">
                  {documentList?.contentCases?.map((x: ContentCase) => (
                    <EventCard
                      key={x.cid}
                      contentCase={x}
                      setSelectedCS={setSelectedCS}
                      selectedCS={selectedCS}
                    />
                  ))}
                </div>
              )}
              <div className="flex justify-center w-full py-8">
                <Pagination
                  currentPage={internalState.get('page') || 0}
                  pageCount={
                    count ? Math.ceil(count / internalState.get('size')) : 1
                  }
                  pageSize={internalState.get('size')}
                  setPageSize={(x: number) => setPaginationState(x, 'size')}
                  gotoPage={(x: number) => setPaginationState(x, 'page')}
                />
              </div>
            </div>
            <ModalInquiry
              isInquiryModalVisible={isInquiryModalVisible}
              toggleInquiryModal={() => setIsInquiryModalVisible(false)}
              selectedCS={selectedCS}
              clearSelectedEvents={() => setSelectedCS(new Map())}
              searchQuery={getSearchPayload(internalState)}
              isSearchQuery={isSearchQuery}
            />
          </div>
        </div>
      </div>
    </HotkeysProvider>
  );
};

export default Explore;
