import { Chart } from 'react-chartjs-2';
import { useUser } from '@context/UserProvider';

const PolicyChart = ({
  counts,
  histogramData,
  threshold,
  upperThreshold
}: any) => {
  const highestCount = (counts && Math.max(...counts)) || 5;
  const { theme } = useUser();

  const getThemeColor = () => (theme === 'light' ? '#303D58' : 'white');
  const data = {
    datasets: [
      {
        type: 'line',
        stack: 'r2',
        label: 'Enforcement rate',
        data: histogramData?.bins?.map((x: any, i: number) => ({
          x: i * 10,
          y: x.score === 0 ? 0 : Math.round((x.enforced / x.count) * 100)
        })),
        borderColor: 'rgba(255, 0, 0, 1)',
        borderWidth: 2,
        fill: false,
        pointStyle: 'circ',
        yAxisID: 'yrAxis'
      },
      {
        type: 'line',
        stack: 'r1',
        label: 'Incident rate',
        data: histogramData?.bins?.map((x: any, i: number) => ({
          x: i * 10,
          y: x.score === 0 ? 0 : Math.round((x.incidents / x.count) * 100) || 0
        })),
        borderColor: 'rgb(0 126 252)',
        fill: false,
        pointStyle: 'circ',
        yAxisID: 'yrAxis'
      },
      {
        type: 'line',
        stack: 'thres',
        label: 'Queue Content Threshold',
        data: [
          {
            x: threshold,
            y: 0
          },
          {
            x: threshold,
            y: highestCount
          }
        ],
        borderColor: 'gray',
        borderWidth: 2,
        fill: false,
        pointStyle: 'circ',
        borderDash: [4, 5],
        yAxisID: 'y'
      },
      {
        type: 'line',
        stack: 'thres',
        label: 'Automatic Enforcement Threshold',
        data: [
          {
            x: upperThreshold,
            y: 0
          },
          {
            x: upperThreshold,
            y: highestCount
          }
        ],
        borderColor: 'red',
        borderWidth: 2,
        fill: false,
        pointStyle: 'circ',
        borderDash: [4, 5],
        yAxisID: 'y'
      },
      {
        type: 'bar',
        stack: 'cases',
        label: 'Below queue threshold',
        data: histogramData?.bins?.map((x: any, i: number) => ({
          x: i * 10,
          y: x.score * 100 < threshold && x.score > 0 ? x.count : null
        })),
        backgroundColor: 'rgb(201 204 210)',
        borderRadius: 20,
        yAxisID: 'y'
      },
      {
        type: 'bar',
        stack: 'cases',
        label: 'Above threshold',
        data: histogramData?.bins?.map((x: any, i: number) => ({
          x: i * 10,
          y: x.score * 100 >= threshold ? x.count : null
        })),
        backgroundColor: 'rgb(129 133 248)',
        hoverBackgroundColor: 'rgb(99 102 241)',
        borderRadius: 20,
        yAxisID: 'y'
      }
    ]
  };

  const options = {
    resizeDelay: 150,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context: any) {
            return `Score: ${context[0].label}%`;
          },
          label: function (context: any) {
            const label = context.dataset.label;
            const stack = context.dataset.stack;
            if (stack === 'cases')
              return `Cases ${label.toLowerCase()}: ${context.parsed.y}`;
            if (stack === 'r1' || stack === 'r2') {
              return `${label}: ${context.parsed.y} %`;
            }
            return label;
          }
        }
      },
      title: {
        display: true,
        text: 'Data over last 28 days',
        padding: 10,
        position: 'bottom',
        color: getThemeColor()
      },
      legend: {
        onClick: () => null,
        display: true,
        position: 'right',
        labels: {
          padding: 25,
          usePointStyle: true,
          generateLabels: function (chart: any) {
            const data = chart.data.datasets;
            return data.map(function (dataset: any) {
              const check = dataset.type === 'line';
              return {
                fontColor: getThemeColor(),
                text: dataset.label,
                fillStyle:
                  dataset.type === 'bar'
                    ? dataset.backgroundColor
                    : 'transparent',
                lineDash: check ? dataset.borderDash : undefined,
                strokeStyle: check ? dataset.borderColor : undefined,
                lineWidth: check ? dataset.borderWidth : undefined,
                pointStyle: dataset.type === 'line' ? 'line' : 'rect'
              };
            });
          }
        }
      }
    },
    layout: {
      padding: {
        top: 20
      }
    },
    scales: {
      x: {
        type: 'linear',
        ticks: { color: getThemeColor() },
        grid: { display: false },
        beginAtZero: true,
        max: 100,
        stepSize: 1
      },
      y: {
        ticks: { color: getThemeColor() },
        title: {
          display: true,
          text: 'Number of Cases',
          padding: 10,
          color: getThemeColor(),
          font: {
            size: 14
          }
        },
        grid: { display: false }
      },
      yrAxis: {
        position: 'right',
        min: 0,
        title: {
          display: true,
          text: 'Rates %',
          color: getThemeColor(),
          font: {
            size: 14
          }
        },
        ticks: {
          color: getThemeColor(),
          max: 11
        },
        type: 'linear',
        grid: { display: false }
      }
    }
  };

  return (
    <Chart
      type="bar"
      options={options as any}
      data={data as any}
      className="p-3 h-[430px] max-w-full"
    />
  );
};

export { PolicyChart };
